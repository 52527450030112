import { Fragment, useState, useEffect } from 'react'
import {
    format,
    setHours,
    setMinutes,
    getMinutes,
    parse,
    differenceInMinutes,
    startOfDay,
    endOfDay,
    addDays,
} from "date-fns";
import Modal from '../Modal';
import BookingModal from './BookingModal';
import {
    useQuery,
    useMutation,
} from 'react-query'
import qs from 'qs';
import { orderBy, has, isEmpty } from 'lodash';
import { Datepicker } from "./Datepicker";
export default function BookingList({ user, token, setDrawerShow, setProduct, showSuccessDialog }) {
    // TODO NO HARD CODE BRAND ID
    const HARD_CODE_DYSON_BRAND_ID = 5;

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [openBookingModal, setOpenBookingModal] = useState(false);

    const [filterStartAt, setFilterStartAt] = useState(new Date());
    const [filterEndAt, setFilterEndAt] = useState(addDays(new Date(), 1));

    const [currentBooking, setCurrentBooking] = useState(null);
    const [bookings, setBookings] = useState([]);
    const [role, setRole] = useState(null);

    const [statusFilter, setStatusFilter] = useState(null);
    const [typeFilter, setTypeFilter] = useState(null);
    const [serachKeyword, setSearchKeyword] = useState('');
    const [previousQ, setPreviousQ] = useState({});

    useEffect(() => {
        setDrawerShow(false);
        setProduct(false);
    }, []);


    // DYSON CHANGE
    // let brandCondition = role === 'DYSON_USER' ? {
    //     brand_eq: HARD_CODE_DYSON_BRAND_ID
    // } : {}

    let brandCondition = {}

    let baseCondition = {
        bookingAt_gte: format(startOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        bookingAt_lte: format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
    }

    let baseQuery = qs.stringify({
        _where: {
            ...brandCondition,
            ...baseCondition
        },
        _limit: -1, _sort: 'bookingAt:DESC'
    });

    const { refetch } = useQuery(['bookings', role], () =>
        fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/bookings?${baseQuery}`)
            .then((res) =>
                res.json()
            ).then(data => {
                const validBookings = data.filter(booking => {
                    return !isEmpty(booking.timeslot)
                });
                setBookings(orderBy(validBookings, ['bookingAt'], ['asc']));
                return validBookings;
            }),
        { refetchOnWindowFocus: false })


    if (user && !role) {
        if (user.jebsenRole) {
            setRole(user.jebsenRole)
        }
    }



    const getStatusColor = (status) => {
        let color;
        switch (status) {
            case 'OPEN': color = 'text-green-500'; break;
            default: color = 'text-red-500'; break; // CANCELED
        }
        return color;
    }

    const openModal = (booking) => {
        setCurrentBooking(booking);
        setOpenDetailModal(true);
    }

    const makeConditions = (q) => {
        const _filterStartAt = has(q, 'startAt') ? q.startAt : filterStartAt;
        const _filterEndAt = has(q, 'endAt') ? q.endAt : filterEndAt;
        const _searchKeyword = has(q, 'keyword') ? q.keyword : serachKeyword;
        const _statusFilter = has(q, 'status') ? q.status : statusFilter;
        const _typeFilter = has(q, 'type') ? q.type : typeFilter;

        // DYSON CHANGE
        // let phoneCondition = user.jebsenRole === 'DYSON_USER' ? [{ brand_eq: HARD_CODE_DYSON_BRAND_ID }] : [];
        // let emailCondition = user.jebsenRole === 'DYSON_USER' ? [{ brand_eq: HARD_CODE_DYSON_BRAND_ID }] : [];
        // let idCondition = user.jebsenRole === 'DYSON_USER' ? [{ brand_eq: HARD_CODE_DYSON_BRAND_ID }] : [];

        let phoneCondition = [];
        let emailCondition = [];
        let idCondition = [];


        if (_filterStartAt) {
            const condition = { bookingAt_gte: format(new Date(_filterStartAt), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") };
            phoneCondition.push(condition);
            emailCondition.push(condition);
            idCondition.push(condition);
        }

        if (_filterEndAt) {
            const condition = { bookingAt_lte: format(setMinutes(setHours(new Date(_filterEndAt), 23), 59), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") };
            phoneCondition.push(condition);
            emailCondition.push(condition);
            idCondition.push(condition);
        }

        if (_searchKeyword) {
            phoneCondition.push({ phone_contains: _searchKeyword });
            emailCondition.push({ email_contains: _searchKeyword });
            idCondition.push({ bookingId_contains: _searchKeyword });
        }

        if (_typeFilter) {
            phoneCondition.push({ serviceType: _typeFilter });
            emailCondition.push({ serviceType: _typeFilter });
            idCondition.push({ serviceType: _typeFilter });
        }

        if (_statusFilter) {
            phoneCondition.push({ status: _statusFilter });
            emailCondition.push({ status: _statusFilter });
            idCondition.push({ status: _statusFilter });
        }
        return [phoneCondition, emailCondition, idCondition];
    }

    const search = (q) => {
        setPreviousQ(q);
        const conditions = makeConditions(q);

        const query = qs.stringify({ _where: { _or: conditions }, _limit: -1, _sort: 'bookingAt:DESC' });
        fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/bookings?${query}`)
            .then((res) => res.json())
            .then(data => {
                setBookings(data);
            })
    }

    const generateReport = (q) => {
        setPreviousQ(q);
        const conditions = makeConditions(q);
        const query = qs.stringify({ _where: { _or: conditions }, _limit: -1, _sort: 'bookingAt:DESC' });
        fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/bookings-export?${query}`).then(res => res.blob())
            .then(blob => {
                const fileURL = URL.createObjectURL(blob);
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;

                // it forces the name of the downloaded file
                fileLink.download = `Booking Report - ${format(new Date(), 'yyyy-MM-dd')}`;

                // triggers the click event
                fileLink.click();
            })
    }

    const searchBoxOnChange = (event) => {
        const value = event.target.value;
        setSearchKeyword(value);
        search({ keyword: value });
    }


    const filterStartAtChange = (value) => {
        setFilterStartAt(value);
        search({ startAt: value });
    }

    const filterEndAtChange = (value) => {
        setFilterEndAt(value);
        search({ endAt: value });
    }


    const typeFilterOnChange = (event) => {
        const value = event.target.value;

        if (value) {
            setTypeFilter(value);
            search({ type: value });
        } else {
            setTypeFilter('');
            search({ type: '' });
        }
    }

    const statusFilterOnChange = (event) => {
        const value = event.target.value;

        if (value) {
            setStatusFilter(value);
            search({ status: value });
        } else {
            setStatusFilter('');
            search({ status: '' });
        }
    }

    return (
        <Fragment>
            <Modal refetch={refetch} search={search} previousQ={previousQ} open={openDetailModal} setOpen={setOpenDetailModal} booking={currentBooking} showSuccessDialog={showSuccessDialog} />
            <BookingModal user={user} open={openBookingModal} setOpen={setOpenBookingModal} />
            <div className="animate-fade-in-down">
                <div className="mb-5 sm:mb-10 rounded-tl-lg rounded-tr-lg">
                    <div className="sm:flex items-center justify-between">
                        {bookings && <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">{bookings.length} records</p>}
                        <div className="flex items-center mt-4 sm:mt-0">
                            <button onClick={() => window.location.reload(false)} className="hidden md:inline-flex ml-3 whitespace-nowrap items-start justify-start px-6 py-3 bg-jebsen-default hover:bg-indigo-600 focus:outline-none rounded">
                                <p className="text-xs sm:text-sm font-medium leading-none text-white">Refresh</p>
                            </button>
                            <button onClick={() => setOpenBookingModal(true)} className="inline-flex ml-3 whitespace-nowrap items-start justify-start px-6 py-3 bg-indigo-700 hover:bg-indigo-600 focus:outline-none rounded">
                                <p className="text-xs sm:text-sm font-medium leading-none text-white">New Booking</p>
                            </button>
                        </div>
                    </div>
                    <div className="mt-4 sm:flex items-center justify-start">
                        <div className="flex items-center mt-4 sm:mt-0">
                            <div className="flex items-center bg-white">
                                <div className="flex flex-col ">
                                    <label className="mb-3 text-sm leading-none text-gray-800">Start Date</label>
                                    <Datepicker date={filterStartAt} onChange={filterStartAtChange}></Datepicker>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center pl-3">
                            <div className="flex flex-col">
                                <label className="mb-3 text-sm leading-none text-gray-800">End Date</label>
                                <Datepicker date={filterEndAt} onChange={filterEndAtChange}></Datepicker>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 sm:flex items-center justify-start">
                        <div className="flex items-center pl-3 bg-white border rounded-md border-gray-200">
                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                                <path d="M8.33333 13.1667C11.555 13.1667 14.1667 10.555 14.1667 7.33333C14.1667 4.11167 11.555 1.5 8.33333 1.5C5.11167 1.5 2.5 4.11167 2.5 7.33333C2.5 10.555 5.11167 13.1667 8.33333 13.1667Z" stroke="#9CA3AF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.5 17.5L12.5 12.5" stroke="#9CA3AF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <input onChange={e => searchBoxOnChange(e)} type="text" className="py-2.5 pl-2 w-40 sm:w-64 focus:outline-none text-sm rounded-md text-gray-600 placeholder-gray-400" placeholder="Mobile / Email / Booking #" />
                        </div>
                        <div className="hidden md:flex items-center mt-4 md:mt-0 md:ml-3 lg:ml-0 mr-1">
                            <div className="w-40 py-2 px-3 bg-white lg:ml-3 border rounded-md border-gray-200">
                                <select onChange={e => statusFilterOnChange(e)} className="w-full text-sm leading-3 text-gray-500 focus:outline-none">
                                    <option value=''>Filter Status</option>
                                    <option value="OPEN">Open</option>
                                    <option value="CANCELED">Cancelled</option>
                                </select>
                            </div>
                        </div>
                        <div className="hidden md:flex items-center mt-4 md:mt-0 md:ml-3 lg:ml-0 mr-1">
                            <div className="w-40 py-2 px-3 bg-white lg:ml-3 border rounded-md border-gray-200">
                                <select onChange={typeFilterOnChange} className="w-full text-sm leading-3 text-gray-500 focus:outline-none">
                                    <option value=''>Filter Type</option>
                                    <option value="REPAIR">Repair</option>
                                    <option value="COLLECTION">Collection</option>
                                    <option value="PURCHASE">Purchase</option>
                                </select>
                            </div>
                        </div>
                        <button onClick={() => generateReport()} className="h-10 inline-flex ml-3 whitespace-nowrap items-start justify-start px-6 py-2 bg-indigo-700 hover:bg-indigo-600 focus:outline-none rounded">
                            <p className="text-xs sm:text-sm font-medium leading-none text-white">Generate CSV</p>
                        </button>
                    </div>
                </div>
                <div className="w-full overflow-x-auto h-screen">
                    <table className="w-full whitespace-nowrap">
                        <thead>
                            <tr className="h-20 w-full text-sm leading-none text-gray-600">
                                <th className="font-normal text-left pl-4">Date</th>
                                <th className="font-normal text-left pl-10">Booking #</th>
                                <th className="font-normal text-left pl-10">Type</th>
                                <th className="font-normal text-left pl-10">Customer Name</th>
                                <th className="font-normal text-left pl-10">Contact</th>
                                <th className="font-normal text-left pl-10">Status</th>
                                <th className="font-normal text-left pl-10 w-32">Brand</th>
                            </tr>
                        </thead>
                        {bookings && <tbody className="w-full">
                            {bookings.map((booking, index) => {

                                if (!isEmpty(booking.timeslot)) {

                                    const startAt = parse(booking.timeslot.startAt, 'HH:mm:ss.SSS', new Date());
                                    const endAt = parse(booking.timeslot.endAt, 'HH:mm:ss.SSS', new Date());
                                    const startTime = setMinutes(setHours(new Date(booking.bookingAt), startAt.getHours()), getMinutes(startAt));
                                    const endTime = setMinutes(setHours(new Date(booking.bookingAt), endAt.getHours()), getMinutes(endAt));


                                    return (<tr key={index} onClick={() => openModal(booking)} className="cursor-pointer h-20 text-sm leading-none text-gray-700 border-b border-t border-gray-200 bg-white hover:bg-gray-50">

                                        <td className="font-medium pl-4">
                                            {format(startTime, 'd MMMM yyyy')}
                                            <p className="text-gray-600 font-normal mt-2">{format(new Date(startTime), 'hh:mm a')} - {differenceInMinutes(endTime, startTime)} mins</p>
                                        </td>
                                        <td className="pl-10 uppercase text-base text-purple-600"><div className="mb-0 bg-gray-200 dark:bg-gray-800 h-8 w-32 rounded-full flex items-center justify-center">
                                            <span className="font-semibold text-xs text-gray-600 dark:text-gray-400 font-normal">{booking.bookingId}</span>
                                        </div></td>
                                        <td className="pl-10 text-base text-purple-600">
                                            <span className="font-semibold text-base text-gray-600 dark:text-gray-400 font-normal">{booking.serviceType}</span>
                                        </td>
                                        <td className="pl-10">
                                            <div className="flex items-center">
                                                {booking.name}
                                            </div>
                                        </td>
                                        <td className="pl-10"><p>{booking.email}</p><p className="pt-2">{booking.countryCode}-{booking.phone}</p></td>
                                        <td className="pl-10">
                                            <div className="w-20 h-6 flex items-center justify-center bg-green-50 rounded-full">
                                                <p className={`font-semibold text-xs capitalize leading-3 ${getStatusColor(booking.status)}`}>{booking.status}</p>
                                            </div>
                                        </td>
                                        <td className="pl-10">{booking.brand.name}</td>
                                        {/* <td className="pl-10">
                                        <div className="flex items-center">
                                            <button onClick={() => openModal(booking)} className="focus:outline-none bg-gray-100 mr-5 hover:bg-gray-200 py-2.5 px-5 rounded text-sm leading-3 text-gray-500">Detail</button>
                                        </div>
                                    </td> */}
                                    </tr>)

                                }
                            })}
                        </tbody>}
                    </table>
                </div>
            </div>
        </Fragment>)
}