import React, { useState, useEffect } from "react";
import adminImg from './admin.png';
import {
    Link,
} from "react-router-dom";
import logo from './logo.jpeg'
import { useHistory } from "react-router-dom";
import verifyToken from './helper/token';
import { capitalize, get, startCase } from 'lodash'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function Bookings({ Content, pageTitle }) {

    const DEFAULT_TIMEOUT = 5000;
    const history = useHistory();

    const [show, setShow] = useState(false);
    const [profile, setProfile] = useState(false);
    const [product, setProduct] = useState(false);
    const [isSuccessDialogShow, setIsSuccessDialogShow] = useState(false);
    const [successDialogText, setSuccessDialogText] = useState('');

    const [token, setToken] = useState('');
    const [user, setUser] = useState(null);

    useEffect(() => {
        const data = verifyToken(setToken, setUser);
        if (!data.isLogin) {
            history.push("/login");
        }
    }, [])

    const signOff = () => {
        showSuccessDialog('Signing off now...');
        setTimeout(() => {
            setToken(null);
            setUser(null);
            cookies.remove('_token', { path: '/' });
            cookies.remove('_user', { path: '/' });
            history.push("/login");
        }, DEFAULT_TIMEOUT)
    }

    const showSuccessDialog = (text) => {
        setIsSuccessDialogShow(true);
        setSuccessDialogText(text);
        setTimeout(() => {
            setIsSuccessDialogShow(false);
        }, DEFAULT_TIMEOUT)
    }
    return (
        <>
            <div>
                {/* Navigation starts */}
                {/* Mobile */}
                <div className={show ? "w-full h-full absolute z-40  transform  translate-x-0 " : "   w-full h-full absolute z-40  transform -translate-x-full"}>
                    <div className="bg-gray-800 opacity-50 inset-0 fixed w-full h-full" onClick={() => setShow(!show)} />
                    <div className="w-64 z-20 absolute left-0 z-40 top-0 bg-white shadow flex-col justify-between transition duration-150 ease-in-out h-full">
                        <div className="flex flex-col justify-between h-full">
                            <div className="px-6 pt-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <img className="w-14 h-14" src={logo} alt="Jebsen Consumer Products Service Centre" />

                                        <p className="text-bold md:text2xl text-base pl-3 text-gray-800">Jebsen Cosumer</p>
                                    </div>
                                    <div id="cross" className=" text-gray-800" onClick={() => setShow(!show)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <line x1={18} y1={6} x2={6} y2={18} />
                                            <line x1={6} y1={6} x2={18} y2={18} />
                                        </svg>
                                    </div>
                                </div>
                                <ul className="f-m-m">
                                    <Link to={'/bookings'}>
                                        <li className="text-white pt-8">
                                            <div className="flex items-center">
                                                <div className="md:w-6 md:h-6 w-5 h-5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                                                        <path d="M7.16667 3H3.83333C3.3731 3 3 3.3731 3 3.83333V7.16667C3 7.6269 3.3731 8 3.83333 8H7.16667C7.6269 8 8 7.6269 8 7.16667V3.83333C8 3.3731 7.6269 3 7.16667 3Z" stroke="#667EEA" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M7.16667 11.6667H3.83333C3.3731 11.6667 3 12.0398 3 12.5V15.8333C3 16.2936 3.3731 16.6667 3.83333 16.6667H7.16667C7.6269 16.6667 8 16.2936 8 15.8333V12.5C8 12.0398 7.6269 11.6667 7.16667 11.6667Z" stroke="#667EEA" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M16.1667 11.6667H12.8333C12.3731 11.6667 12 12.0398 12 12.5V15.8334C12 16.2936 12.3731 16.6667 12.8333 16.6667H16.1667C16.6269 16.6667 17 16.2936 17 15.8334V12.5C17 12.0398 16.6269 11.6667 16.1667 11.6667Z" stroke="#667EEA" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M16.1667 3H12.8333C12.3731 3 12 3.3731 12 3.83333V7.16667C12 7.6269 12.3731 8 12.8333 8H16.1667C16.6269 8 17 7.6269 17 7.16667V3.83333C17 3.3731 16.6269 3 16.1667 3Z" stroke="#667EEA" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <p className={`${pageTitle === 'Bookings' ? 'text-indigo-500' : 'text-gray-800'} ml-3 text-lg`}>Bookings</p>
                                            </div>
                                        </li>
                                    </Link>
                                    {get(user, 'jebsenRole', '') === 'ADMIN' && <Link to={'/accounts'}>
                                        <li className="text-gray-800 pt-8">
                                            <div className="flex items-center">
                                                <div className="md:w-6 md:h-6 w-5 h-5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                                                        <path d="M6.66667 13.3333L8.33334 8.33334L13.3333 6.66667L11.6667 11.6667L6.66667 13.3333Z" stroke="currentColor" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="currentColor" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <p className={`${pageTitle === 'Accounts' ? 'text-indigo-500' : 'text-gray-800'} ml-3 text-lg`}>Accounts</p>
                                            </div>
                                        </li>
                                    </Link>}
                                    {/* <Link to={'/config'}>
                                        <li className="text-gray-800 pt-8">
                                            <div className="flex items-center">
                                                <div className="md:w-6 md:h-6 w-5 h-5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                                        <path
                                                            d="M2.33333 4.83333H4.83333C5.05435 4.83333 5.26631 4.74554 5.42259 4.58926C5.57887 4.43298 5.66667 4.22101 5.66667 4V3.16667C5.66667 2.72464 5.84226 2.30072 6.15482 1.98816C6.46738 1.67559 6.89131 1.5 7.33333 1.5C7.77536 1.5 8.19928 1.67559 8.51184 1.98816C8.8244 2.30072 9 2.72464 9 3.16667V4C9 4.22101 9.0878 4.43298 9.24408 4.58926C9.40036 4.74554 9.61232 4.83333 9.83333 4.83333H12.3333C12.5543 4.83333 12.7663 4.92113 12.9226 5.07741C13.0789 5.23369 13.1667 5.44565 13.1667 5.66667V8.16667C13.1667 8.38768 13.2545 8.59964 13.4107 8.75592C13.567 8.9122 13.779 9 14 9H14.8333C15.2754 9 15.6993 9.17559 16.0118 9.48816C16.3244 9.80072 16.5 10.2246 16.5 10.6667C16.5 11.1087 16.3244 11.5326 16.0118 11.8452C15.6993 12.1577 15.2754 12.3333 14.8333 12.3333H14C13.779 12.3333 13.567 12.4211 13.4107 12.5774C13.2545 12.7337 13.1667 12.9457 13.1667 13.1667V15.6667C13.1667 15.8877 13.0789 16.0996 12.9226 16.2559C12.7663 16.4122 12.5543 16.5 12.3333 16.5H9.83333C9.61232 16.5 9.40036 16.4122 9.24408 16.2559C9.0878 16.0996 9 15.8877 9 15.6667V14.8333C9 14.3913 8.8244 13.9674 8.51184 13.6548C8.19928 13.3423 7.77536 13.1667 7.33333 13.1667C6.89131 13.1667 6.46738 13.3423 6.15482 13.6548C5.84226 13.9674 5.66667 14.3913 5.66667 14.8333V15.6667C5.66667 15.8877 5.57887 16.0996 5.42259 16.2559C5.26631 16.4122 5.05435 16.5 4.83333 16.5H2.33333C2.11232 16.5 1.90036 16.4122 1.74408 16.2559C1.5878 16.0996 1.5 15.8877 1.5 15.6667V13.1667C1.5 12.9457 1.5878 12.7337 1.74408 12.5774C1.90036 12.4211 2.11232 12.3333 2.33333 12.3333H3.16667C3.60869 12.3333 4.03262 12.1577 4.34518 11.8452C4.65774 11.5326 4.83333 11.1087 4.83333 10.6667C4.83333 10.2246 4.65774 9.80072 4.34518 9.48816C4.03262 9.17559 3.60869 9 3.16667 9H2.33333C2.11232 9 1.90036 8.9122 1.74408 8.75592C1.5878 8.59964 1.5 8.38768 1.5 8.16667V5.66667C1.5 5.44565 1.5878 5.23369 1.74408 5.07741C1.90036 4.92113 2.11232 4.83333 2.33333 4.83333"
                                                            stroke="currentColor"
                                                            strokeWidth={1}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                                <p className={`${pageTitle === 'Configurations' ? 'text-indigo-500' : 'text-gray-800'} ml-3 text-lg`}>Configurations</p>
                                            </div>
                                        </li>
                                    </Link> */}

                                    {get(user, 'jebsenRole', '') === 'ADMIN' && <li className="text-gray-700 pt-8">
                                        <div className="flex items-center">
                                            <div className="flex items-center">
                                                <div className="md:w-6 md:h-6 w-5 h-5">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                                                        <path
                                                            d="M2.33333 4.83333H4.83333C5.05435 4.83333 5.26631 4.74554 5.42259 4.58926C5.57887 4.43298 5.66667 4.22101 5.66667 4V3.16667C5.66667 2.72464 5.84226 2.30072 6.15482 1.98816C6.46738 1.67559 6.89131 1.5 7.33333 1.5C7.77536 1.5 8.19928 1.67559 8.51184 1.98816C8.8244 2.30072 9 2.72464 9 3.16667V4C9 4.22101 9.0878 4.43298 9.24408 4.58926C9.40036 4.74554 9.61232 4.83333 9.83333 4.83333H12.3333C12.5543 4.83333 12.7663 4.92113 12.9226 5.07741C13.0789 5.23369 13.1667 5.44565 13.1667 5.66667V8.16667C13.1667 8.38768 13.2545 8.59964 13.4107 8.75592C13.567 8.9122 13.779 9 14 9H14.8333C15.2754 9 15.6993 9.17559 16.0118 9.48816C16.3244 9.80072 16.5 10.2246 16.5 10.6667C16.5 11.1087 16.3244 11.5326 16.0118 11.8452C15.6993 12.1577 15.2754 12.3333 14.8333 12.3333H14C13.779 12.3333 13.567 12.4211 13.4107 12.5774C13.2545 12.7337 13.1667 12.9457 13.1667 13.1667V15.6667C13.1667 15.8877 13.0789 16.0996 12.9226 16.2559C12.7663 16.4122 12.5543 16.5 12.3333 16.5H9.83333C9.61232 16.5 9.40036 16.4122 9.24408 16.2559C9.0878 16.0996 9 15.8877 9 15.6667V14.8333C9 14.3913 8.8244 13.9674 8.51184 13.6548C8.19928 13.3423 7.77536 13.1667 7.33333 13.1667C6.89131 13.1667 6.46738 13.3423 6.15482 13.6548C5.84226 13.9674 5.66667 14.3913 5.66667 14.8333V15.6667C5.66667 15.8877 5.57887 16.0996 5.42259 16.2559C5.26631 16.4122 5.05435 16.5 4.83333 16.5H2.33333C2.11232 16.5 1.90036 16.4122 1.74408 16.2559C1.5878 16.0996 1.5 15.8877 1.5 15.6667V13.1667C1.5 12.9457 1.5878 12.7337 1.74408 12.5774C1.90036 12.4211 2.11232 12.3333 2.33333 12.3333H3.16667C3.60869 12.3333 4.03262 12.1577 4.34518 11.8452C4.65774 11.5326 4.83333 11.1087 4.83333 10.6667C4.83333 10.2246 4.65774 9.80072 4.34518 9.48816C4.03262 9.17559 3.60869 9 3.16667 9H2.33333C2.11232 9 1.90036 8.9122 1.74408 8.75592C1.5878 8.59964 1.5 8.38768 1.5 8.16667V5.66667C1.5 5.44565 1.5878 5.23369 1.74408 5.07741C1.90036 4.92113 2.11232 4.83333 2.33333 4.83333"
                                                            stroke="currentColor"
                                                            strokeWidth={1}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                                <p className={`${pageTitle === 'Configurations' ? 'text-indigo-500' : 'text-gray-800'} ml-3 text-lg`}>Configurations</p>
                                            </div>
                                            <div onClick={() => setProduct(!product)}>
                                                {product ? (
                                                    <div className=" ml-4">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-up" width={14} height={14} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <polyline points="6 15 12 9 18 15" />
                                                        </svg>
                                                    </div>
                                                ) : (
                                                    <div className="ml-4">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width={14} height={14} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <polyline points="6 9 12 15 18 9" />
                                                        </svg>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {product ? (
                                            <div>
                                                <ul className="my-3">
                                                    <Link to={'/config'}>
                                                        <li className={`${pageTitle === 'Configurations' ? 'text-indigo-500' : 'text-gray-800'} py-2 px-6 text-lg`}>Bookings</li>
                                                    </Link>
                                                    <Link to={'/timeslots'}>
                                                        <li className="text-sm text-gray-800 hover:text-indigo-500 py-2 px-6 text-lg">Timeslots</li>
                                                    </Link>
                                                    <Link to={'/brands'}>
                                                        <li className="text-sm text-gray-800 hover:text-indigo-500 py-2 px-6 text-lg">Brands</li>
                                                    </Link>
                                                </ul>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </li>}


                                </ul>
                            </div>
                            <div className="w-full">
                                <div className="flex justify-center mb-4 w-full px-6">
                                    <div className="relative w-full">
                                        <div className="text-gray-500 absolute ml-4 inset-0 m-auto w-4 h-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={16} height={16} viewBox="0 0 24 24" strokeWidth={1} stroke="#A0AEC0" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <circle cx={10} cy={10} r={7} />
                                                <line x1={21} y1={21} x2={15} y2={15} />
                                            </svg>
                                        </div>
                                        <input className="bg-gray-100 focus:outline-none rounded w-full text-sm text-gray-500 bg-gray-100 pl-10 py-2" type="text" placeholder="Search Booking #" />
                                    </div>
                                </div>
                                <div className="border-t border-gray-300">
                                    <div className="w-full flex items-center justify-between px-6 py-3">
                                        <div className="flex items-center">
                                            <img alt="profile-pic" src={adminImg} className="w-8 h-8 rounded-md" />
                                            <p className=" text-gray-800 text-base leading-4 ml-3">Henry<p className="text-gray-600 text-sm">Administrator</p></p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Mobile */}
                <nav className="w-full mx-auto bg-white shadow relative z-20">
                    <div className="justify-between container px-6 h-16 flex items-center lg:items-stretch mx-auto">
                        <div className="flex items-center">
                            <div className="mr-10 flex items-center">
                                <img className="w-14 h-14" src={logo} alt="Jebsen Consumer Products Service Centre" />
                                <h3 className="text-base text-gray-800 font-bold tracking-normal leading-tight ml-3 hidden lg:block">Jebsen Consumer</h3>
                            </div>
                            <ul className="pr-32 xl:flex hidden items-center h-full">
                                <Link to={'/bookings'}>
                                    <li className={`p-4 hover:text-indigo-700 cursor-pointer h-full flex items-center text-sm ${pageTitle === 'Bookings' ? 'text-indigo-700' : 'text-gray-800'} tracking-normal`}>Bookings</li>
                                </Link>
                                {get(user, 'jebsenRole', '') === 'ADMIN' && <Link to={'/accounts'}>
                                    <li className={`p-4 hover:text-indigo-700 cursor-pointer h-full flex items-center text-sm ${pageTitle === 'Accounts' ? 'text-indigo-700' : 'text-gray-800'} ml-4 tracking-normal`}>Accounts</li>
                                </Link>}
                                {/* <Link to={'/config'}>
                                    <li className={`hover:text-indigo-700 cursor-pointer h-full flex items-center text-sm ${pageTitle === 'Configurations' ? 'text-indigo-700' : 'text-gray-800'} mr-10 tracking-normal`}>Configurations</li>
                                </Link> */}
                                {get(user, 'jebsenRole', '') === 'ADMIN' && <Link to={'/timeslots'}>
                                    <li className={`p-4 hover:text-indigo-700 cursor-pointer h-full flex items-center text-sm ${pageTitle === 'Timeslots' ? 'text-indigo-700' : 'text-gray-800'} ml-4 tracking-normal`}>Timeslots</li>
                                </Link>}
                                {get(user, 'jebsenRole', '') === 'ADMIN' && <Link to={'/brands'}>
                                    <li className={`p-4 hover:text-indigo-700 cursor-pointer h-full flex items-center text-sm ${pageTitle === 'Brands' ? 'text-indigo-700' : 'text-gray-800'} ml-4 tracking-normal`}>Brands</li>
                                </Link>}
                                {get(user, 'jebsenRole', '') === 'ADMIN' && <Link to={'/config'}>
                                    <li className={`p-4 hover:text-indigo-700 cursor-pointer h-full flex items-center text-sm ${pageTitle === 'Configurations' ? 'text-indigo-700' : 'text-gray-800'} ml-4 tracking-normal`}>Configurations</li>
                                </Link>}
                                {/* {get(user, 'jebsenRole', '') === 'ADMIN' && <li onClick={() => setProduct(!product)} className={`hover:text-indigo-700 cursor-pointer h-full flex items-center text-sm ${pageTitle === 'Configurations' ? 'text-indigo-700' : 'text-gray-800'} mr-10 tracking-normal relative`}>
                                    {product ? (
                                        <ul className="bg-white shadow rounded py-1 w-32 left-0 mt-16 -ml-4 absolute  top-0">
                                            <Link to={'/config'}>
                                                <li className={`cursor-pointer ${pageTitle === 'Configurations' ? 'text-indigo-700' : 'text-gray-600'} text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal`}>Bookings</li>
                                            </Link>
                                            <Link to={'/timeslots'}>
                                                <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Timeslots</li>
                                            </Link>
                                            <Link to={'/brands'}>
                                                <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">Brands</li>
                                            </Link>
                                        </ul>
                                    ) : (
                                        ""
                                    )}
                                    Configurations
                                    <span className="ml-2" onClick={() => setProduct(!product)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width={16} height={16} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <polyline points="6 9 12 15 18 9" />
                                        </svg>
                                    </span>
                                </li>} */}
                            </ul>
                        </div>
                        <div className="h-full xl:flex hidden items-center justify-end">
                            <div className="h-full flex items-center">
                                <div className="w-32 pr-16 h-full flex items-center justify-end border-r" />
                                <div className="w-full h-full flex">
                                    <div aria-haspopup="true" className="cursor-pointer w-full flex items-center justify-center relative" onClick={() => setProfile(!profile)}>
                                        {profile ? (
                                            <ul className="p-2 w-40 border-r bg-white absolute rounded z-40 left-0 shadow mt-44">
                                                <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                                                    <div className="flex items-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width={20} height={20} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" />
                                                            <circle cx={12} cy={7} r={4} />
                                                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                        </svg>
                                                        <span className="ml-2">Profile (Disabled)</span>
                                                    </div>
                                                </li>
                                                <li onClick={() => signOff()}  className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 hover:text-indigo-700 flex items-center focus:text-indigo-700 focus:outline-none">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-settings" width={20} height={20} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" />
                                                        <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                                        <circle cx={12} cy={12} r={3} />
                                                    </svg>
                                                    <span className="ml-2">Sign Off</span>
                                                </li>
                                            </ul>
                                        ) : (
                                            ""
                                        )}
                                        <img className="rounded-full h-10 w-10 object-cover" src={adminImg} alt="avatar" />
                                        <p className="text-gray-800 text-sm ml-3">{get(user, 'username', '')}<p className="text-gray-600 text-xs">{get(user, 'email', '')}</p><p className="text-gray-600 text-xs">{capitalize(startCase(get(user, 'jebsenRole', '')))}</p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="visible xl:hidden flex items-center">
                            {/*   <ul class="z-40 p-2 border-r bg-white absolute rounded top-0 left-0 right-0 shadow mt-16 md:mt-16 hidden">
                          <li class="flex xl:hidden cursor-pointer text-gray-600 text-base leading-3 tracking-normal mt-2 py-3 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                              <div class="flex items-center">
                                  <span class="leading-6 ml-2 font-bold">Dashboard</span>
                              </div>
                          </li>
                          <li class="flex xl:hidden flex-col cursor-pointer text-gray-600 text-base leading-3 tracking-normal py-3 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none flex justify-center"
                              >
                              <div class="flex items-center">
                                  <span class="leading-6 ml-2 font-bold">Products</span>
                              </div>
                              <ul class="ml-2 mt-3 hidden">
                                  <li class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">
                                      Landing Pages
                                  </li>
                                  <li class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">
                                      Templates
                                  </li>
                                  <li class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">
                                      Components
                                  </li>
                              </ul>
                          </li>
                          <li class="flex xl:hidden cursor-pointer text-gray-600 text-base leading-3 tracking-normal py-3 hover:text-indigo-700 flex items-center focus:text-indigo-700 focus:outline-none">
                              <span class="leading-6 ml-2 font-bold">Performance</span>
                          </li>
                         <li class="flex xl:hidden flex-col cursor-pointer text-gray-600 text-base leading-3 tracking-normal mb-2 py-3 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none flex justify-center"
                             
                         >
                              <div class="flex items-center">
                                  <span class="leading-6 ml-2 font-bold">Deliverables</span>
                              </div>
                              <ul class="ml-2 mt-3 hidden">
                                  <li class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">
                                      Landing Pages
                                  </li>
                                  <li class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">
                                      Templates
                                  </li>
                                  <li class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-indigo-700 hover:text-white px-3 font-normal">
                                      Components
                                  </li>
                              </ul>
                          </li>
                          <li>
                              <hr class="border-b border-gray-300 w-full"/>
                          </li>
                          <li class="ml-2 cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 hover:text-indigo-700 flex items-center focus:text-indigo-700 focus:outline-none">
                              <div class="flex items-center">
                                  <div class="w-12 cursor-pointer flex text-sm border-2 border-transparent rounded focus:outline-none focus:border-white transition duration-150 ease-in-out">
                                      <img class="rounded h-10 w-10 object-cover"
                                           src="https://tuk-cdn.s3.amazonaws.com/assets/components/horizontal_navigation/hn_1.png"
                                           alt="logo">
                                  </div>
                                  <p class="leading-6 text-base ml-1 cursor-pointer">Jane Doe</p>
                                  <div class="sm:ml-2 text-white relative">
                                      <svg xmlns="http://www.w3.org/2000/svg"
                                           class="icon icon-tabler icon-tabler-chevron-down cursor-pointer"
                                           width="20" height="20" viewBox="0 0 24 24" stroke-width="1"
                                           stroke="currentColor" fill="none" stroke-linecap="round"
                                           stroke-linejoin="round">
                                          <path stroke="none" d="M0 0h24v24H0z"></path>
                                          <polyline points="6 9 12 15 18 9"></polyline>
                                      </svg>
                                  </div>
                              </div>
                              <p class="leading-6 text-base ml-1 cursor-pointer">Jane Doe</p>
                              <div class="sm:ml-2 text-white relative">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down cursor-pointer" width="20" height="20" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z"></path>
                                      <polyline points="6 9 12 15 18 9"></polyline>
                                  </svg>
                              </div>
                          </li>
                           
                      </ul> */}
                            <div>
                                <div id="menu" className="text-gray-800" onClick={() => setShow(!show)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-menu-2" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1={4} y1={6} x2={20} y2={6} />
                                        <line x1={4} y1={12} x2={20} y2={12} />
                                        <line x1={4} y1={18} x2={20} y2={18} />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                {/* Navigation ends */}
                {/* Page title starts */}
                <div className="bg-gray-800 py-6 lg:py-8">
                    <div className="container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between">
                        <div>
                            <h4 className="text-2xl font-bold leading-tight text-white">{pageTitle}</h4>
                        </div>
                        {/* <div className="mt-6 lg:mt-0">
                            <button className="focus:outline-none mr-3 bg-transparent transition duration-150 ease-in-out hover:bg-gray-700 rounded text-white px-5 py-2 text-sm border border-white">Back</button>
                            <button className="focus:outline-none transition duration-150 ease-in-out hover:bg-gray-200 border bg-white rounded text-indigo-700 px-8 py-2 text-sm">Bookings</button>
                        </div> */}
                    </div>
                </div>
                {/* Page title ends */}
                <div className="w-full pt-12 bg-gray-200">
                    {isSuccessDialogShow && <div className="shadow animate-fade-in-down sticky top-5 right-10 z-50 justify-center p-2 w-96 mb-4 bg-green-400 rounded">
                        <div className="flex justify-center">
                            <div className="flex items-center">
                                <div className="mr-2 text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} fill="currentColor">
                                        <path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z" />
                                    </svg>
                                </div>
                                <p className="mr-2 text-xl font-bold text-white">Success</p>
                            </div>
                            <div className="flex items-center ml-4">
                                <p className="text-base text-white pt-2 sm:pt-0 pb-2 sm:pb-0">{successDialogText}</p>
                            </div>
                            <div onClick={() => setIsSuccessDialogShow(false)} className="ml-4 flex items-center cursor-pointer text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
                                    <line x1={18} y1={6} x2={6} y2={18} />
                                    <line x1={6} y1={6} x2={18} y2={18} />
                                </svg>
                            </div>
                        </div>
                    </div>}
                    {/* Remove class [ h-64 ] when adding a card block */}
                    <div className="h-full container mx-auto px-6 pb-10 h-64">
                        {/* Remove class [ border-dashed border-2 border-gray-300 ] to remove dotted border */}
                        <div className="w-full h-full rounded border-dashed border-2 border-gray-300">

                            <>
                                <div className="bg-white shadow w-full px-6 sm:px-12 py-5 sm:py-10">
                                    <Content user={user} token={token}setDrawerShow={setShow} setProduct={setProduct} showSuccessDialog={showSuccessDialog} />
                                </div>
                            </>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}