import React, { useEffect, useRef, useState } from "react";
import logo from '../logo.jpeg'
import axios from 'axios'
import { useHistory } from "react-router-dom";
import verifyToken from '../helper/token';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Index = () => {
    const history = useHistory();
    const [isSuccessDialogShow, setIsSuccessDialogShow] = useState(false);
    const [successDialogText, setSuccessDialogText] = useState('');
    const DEFAULT_TIMEOUT = 2000;

    useEffect(() => {
        const data = verifyToken();
        if (data.isLogin) {
            history.push("/bookings");
        }
    }, [])

    let form = useRef(null);
    const handleSubmit = async (event) => {
        event.preventDefault();
        const form_data = new FormData(form.current);
        let payload = {};
        form_data.forEach(function (value, key) {
            payload[key] = value;
        });

        axios
            .post(`${process.env.REACT_APP_STRAPI_DOMAIN}/auth/local`, {
                identifier: payload.email,
                password: payload.password
            })
            .then(response => {
                console.log('User profile', response.data.user);
                console.log('User token', response.data.jwt);
                // Handle success.
                setIsSuccessDialogShow(true);
                cookies.remove('_token', { path: '/' });
                cookies.remove('_user', { path: '/' });
                cookies.set('_token', JSON.stringify(response.data.jwt), { path: '/' });
                cookies.set('_user', JSON.stringify(response.data.user), { path: '/' });

                setSuccessDialogText('Redirecting...');
                setTimeout(() => {
                    setIsSuccessDialogShow(false);
                    history.push("/bookings");
                }, DEFAULT_TIMEOUT)

            })
            .catch(error => {
                // TODO: Handle error.
                console.log('An error occurred:', error.response);
            });

        // Place your API call here to submit your payload.
    };
    return (
        <div>
            <section className="bg-white dark:bg-gray-900 {-- h-screen --}">
                {isSuccessDialogShow && <div className="shadow animate-fade-in-down sticky top-5 right-10 z-50 justify-center p-2 w-96 mb-4 bg-green-400 rounded">
                    <div className="flex justify-center">
                        <div className="flex items-center">
                            <div className="mr-2 text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={20} height={20} fill="currentColor">
                                    <path className="heroicon-ui" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z" />
                                </svg>
                            </div>
                            <p className="mr-2 text-xl font-bold text-white">Success</p>
                        </div>
                        <div className="flex items-center ml-4">
                            <p className="text-base text-white pt-2 sm:pt-0 pb-2 sm:pb-0">{successDialogText}</p>
                        </div>
                        <div onClick={() => setIsSuccessDialogShow(false)} className="ml-4 flex items-center cursor-pointer text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                            </svg>
                        </div>
                    </div>
                </div>}
                <div className="mx-auto flex justify-center md:items-center relative md:h-full">
                    <div className="absolute top-0 right-0 pt-2 mr-4">
                        <svg width={200} height={144} xmlns="http://www.w3.org/2000/svg">
                            <g fill="#667EEA" fillRule="evenodd">
                                <rect width={4} height={4} rx={2} />
                                <rect x={23} width={4} height={4} rx={2} />
                                <rect x={45} width={4} height={4} rx={2} />
                                <rect x={68} width={4} height={4} rx={2} />
                                <rect x={90} width={4} height={4} rx={2} />
                                <rect x={113} width={4} height={4} rx={2} />
                                <rect x={135} width={4} height={4} rx={2} />
                                <rect x={158} width={4} height={4} rx={2} />
                                <rect x={181} width={4} height={4} rx={2} />
                                <rect x={203} width={4} height={4} rx={2} />
                                <rect y={18} width={4} height={4} rx={2} />
                                <rect x={23} y={18} width={4} height={4} rx={2} />
                                <rect x={45} y={18} width={4} height={4} rx={2} />
                                <rect x={68} y={18} width={4} height={4} rx={2} />
                                <rect x={90} y={18} width={4} height={4} rx={2} />
                                <rect x={113} y={18} width={4} height={4} rx={2} />
                                <rect x={135} y={18} width={4} height={4} rx={2} />
                                <rect x={158} y={18} width={4} height={4} rx={2} />
                                <rect x={181} y={18} width={4} height={4} rx={2} />
                                <rect x={203} y={18} width={4} height={4} rx={2} />
                                <rect y={35} width={4} height={4} rx={2} />
                                <rect x={23} y={35} width={4} height={4} rx={2} />
                                <rect x={45} y={35} width={4} height={4} rx={2} />
                                <rect x={68} y={35} width={4} height={4} rx={2} />
                                <rect x={90} y={35} width={4} height={4} rx={2} />
                                <rect x={113} y={35} width={4} height={4} rx={2} />
                                <rect x={135} y={35} width={4} height={4} rx={2} />
                                <rect x={158} y={35} width={4} height={4} rx={2} />
                                <rect x={181} y={35} width={4} height={4} rx={2} />
                                <rect x={203} y={35} width={4} height={4} rx={2} />
                                <rect y={53} width={4} height={4} rx={2} />
                                <rect x={23} y={53} width={4} height={4} rx={2} />
                                <rect x={45} y={53} width={4} height={4} rx={2} />
                                <rect x={68} y={53} width={4} height={4} rx={2} />
                                <rect x={90} y={53} width={4} height={4} rx={2} />
                                <rect x={113} y={53} width={4} height={4} rx={2} />
                                <rect x={135} y={53} width={4} height={4} rx={2} />
                                <rect x={158} y={53} width={4} height={4} rx={2} />
                                <rect x={181} y={53} width={4} height={4} rx={2} />
                                <rect x={203} y={53} width={4} height={4} rx={2} />
                                <rect y={70} width={4} height={4} rx={2} />
                                <rect x={23} y={70} width={4} height={4} rx={2} />
                                <rect x={45} y={70} width={4} height={4} rx={2} />
                                <rect x={68} y={70} width={4} height={4} rx={2} />
                                <rect x={90} y={70} width={4} height={4} rx={2} />
                                <rect x={113} y={70} width={4} height={4} rx={2} />
                                <rect x={135} y={70} width={4} height={4} rx={2} />
                                <rect x={158} y={70} width={4} height={4} rx={2} />
                                <rect x={181} y={70} width={4} height={4} rx={2} />
                                <rect x={203} y={70} width={4} height={4} rx={2} />
                                <rect y={88} width={4} height={4} rx={2} />
                                <rect x={23} y={88} width={4} height={4} rx={2} />
                                <rect x={45} y={88} width={4} height={4} rx={2} />
                                <rect x={68} y={88} width={4} height={4} rx={2} />
                                <rect x={90} y={88} width={4} height={4} rx={2} />
                                <rect x={113} y={88} width={4} height={4} rx={2} />
                                <rect x={135} y={88} width={4} height={4} rx={2} />
                                <rect x={158} y={88} width={4} height={4} rx={2} />
                                <rect x={181} y={88} width={4} height={4} rx={2} />
                                <rect x={203} y={88} width={4} height={4} rx={2} />
                                <rect y={105} width={4} height={4} rx={2} />
                                <rect x={23} y={105} width={4} height={4} rx={2} />
                                <rect x={45} y={105} width={4} height={4} rx={2} />
                                <rect x={68} y={105} width={4} height={4} rx={2} />
                                <rect x={90} y={105} width={4} height={4} rx={2} />
                                <rect x={113} y={105} width={4} height={4} rx={2} />
                                <rect x={135} y={105} width={4} height={4} rx={2} />
                                <rect x={158} y={105} width={4} height={4} rx={2} />
                                <rect x={181} y={105} width={4} height={4} rx={2} />
                                <rect x={203} y={105} width={4} height={4} rx={2} />
                                <rect y={123} width={4} height={4} rx={2} />
                                <rect x={23} y={123} width={4} height={4} rx={2} />
                                <rect x={45} y={123} width={4} height={4} rx={2} />
                                <rect x={68} y={123} width={4} height={4} rx={2} />
                                <rect x={90} y={123} width={4} height={4} rx={2} />
                                <rect x={113} y={123} width={4} height={4} rx={2} />
                                <rect x={135} y={123} width={4} height={4} rx={2} />
                                <rect x={158} y={123} width={4} height={4} rx={2} />
                                <rect x={181} y={123} width={4} height={4} rx={2} />
                                <rect x={203} y={123} width={4} height={4} rx={2} />
                                <rect y={140} width={4} height={4} rx={2} />
                                <rect x={23} y={140} width={4} height={4} rx={2} />
                                <rect x={45} y={140} width={4} height={4} rx={2} />
                                <rect x={68} y={140} width={4} height={4} rx={2} />
                                <rect x={90} y={140} width={4} height={4} rx={2} />
                                <rect x={113} y={140} width={4} height={4} rx={2} />
                                <rect x={135} y={140} width={4} height={4} rx={2} />
                                <rect x={158} y={140} width={4} height={4} rx={2} />
                                <rect x={181} y={140} width={4} height={4} rx={2} />
                                <rect x={203} y={140} width={4} height={4} rx={2} />
                            </g>
                        </svg>
                    </div>

                    <form id="login" className="w-full sm:w-4/6 md:w-3/6 lg:w-4/12 xl:w-3/12 text-gray-800 mb-32 sm:mb-0 my-40 sm:my-12 px-2 sm:px-0" onSubmit={handleSubmit} ref={form}>
                        <div className="px-2 flex flex-col items-center justify-center mt-8 sm:mt-0">
                            <img className="w-40 h-40" src={logo} alt="Jebsen Consumer Products Service Centre" />
                            <h2 className="text-4xl dark:text-gray-100 leading-tight pt-8">Jebsen Online Booking</h2>
                        </div>
                        <div className="pt-16 px-2 flex flex-col items-center justify-center">
                            <h3 className="text-2xl sm:text-3xl xl:text-2xl font-bold dark:text-gray-100 leading-tight">Login To Your Account</h3>
                        </div>
                        <div className="mb-4 mt-12 w-full px-2 sm:px-6">
                            <div className="flex flex-col mt-5">
                                <label htmlFor="email" className="text-lg font-semibold dark:text-gray-100 leading-tight">
                                    Email
                                </label>
                                <input required name="email" id="email" className="h-10 px-2 w-full rounded mt-2 text-gray-600 dark:text-gray-400 bg-white dark:bg-gray-800 dark:border-gray-700 dark:focus:border-indigo-600 focus:outline-none focus:border focus:border-indigo-700 border-gray-300 border shadow" type="email" />
                            </div>
                            <div className="flex flex-col mt-5">
                                <label htmlFor="password" className="text-lg font-semibold dark:text-gray-100 fleading-tight">
                                    Password
                                </label>
                                <input required name="password" id="password" className="h-10 px-2 w-full rounded mt-2 text-gray-600 dark:text-gray-400 bg-white dark:bg-gray-800 dark:border-gray-700 dark:focus:border-indigo-600 focus:outline-none focus:border focus:border-indigo-700 border-gray-300 border shadow" type="password" />
                            </div>
                        </div>
                        <div className="px-2 mb-16 sm:mb-56 md:mb-16 sm:px-6">
                            <button className="focus:outline-none w-full bg-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-8 py-3 text-sm mt-6">Login</button>
                        </div>
                    </form>
                    <div className="absolute bottom-0 left-0 pb-2 ml-4">
                        <svg width={200} height={144} xmlns="http://www.w3.org/2000/svg">
                            <g fill="#667EEA" fillRule="evenodd">
                                <rect width={4} height={4} rx={2} />
                                <rect x={23} width={4} height={4} rx={2} />
                                <rect x={45} width={4} height={4} rx={2} />
                                <rect x={68} width={4} height={4} rx={2} />
                                <rect x={90} width={4} height={4} rx={2} />
                                <rect x={113} width={4} height={4} rx={2} />
                                <rect x={135} width={4} height={4} rx={2} />
                                <rect x={158} width={4} height={4} rx={2} />
                                <rect x={181} width={4} height={4} rx={2} />
                                <rect x={203} width={4} height={4} rx={2} />
                                <rect y={18} width={4} height={4} rx={2} />
                                <rect x={23} y={18} width={4} height={4} rx={2} />
                                <rect x={45} y={18} width={4} height={4} rx={2} />
                                <rect x={68} y={18} width={4} height={4} rx={2} />
                                <rect x={90} y={18} width={4} height={4} rx={2} />
                                <rect x={113} y={18} width={4} height={4} rx={2} />
                                <rect x={135} y={18} width={4} height={4} rx={2} />
                                <rect x={158} y={18} width={4} height={4} rx={2} />
                                <rect x={181} y={18} width={4} height={4} rx={2} />
                                <rect x={203} y={18} width={4} height={4} rx={2} />
                                <rect y={35} width={4} height={4} rx={2} />
                                <rect x={23} y={35} width={4} height={4} rx={2} />
                                <rect x={45} y={35} width={4} height={4} rx={2} />
                                <rect x={68} y={35} width={4} height={4} rx={2} />
                                <rect x={90} y={35} width={4} height={4} rx={2} />
                                <rect x={113} y={35} width={4} height={4} rx={2} />
                                <rect x={135} y={35} width={4} height={4} rx={2} />
                                <rect x={158} y={35} width={4} height={4} rx={2} />
                                <rect x={181} y={35} width={4} height={4} rx={2} />
                                <rect x={203} y={35} width={4} height={4} rx={2} />
                                <rect y={53} width={4} height={4} rx={2} />
                                <rect x={23} y={53} width={4} height={4} rx={2} />
                                <rect x={45} y={53} width={4} height={4} rx={2} />
                                <rect x={68} y={53} width={4} height={4} rx={2} />
                                <rect x={90} y={53} width={4} height={4} rx={2} />
                                <rect x={113} y={53} width={4} height={4} rx={2} />
                                <rect x={135} y={53} width={4} height={4} rx={2} />
                                <rect x={158} y={53} width={4} height={4} rx={2} />
                                <rect x={181} y={53} width={4} height={4} rx={2} />
                                <rect x={203} y={53} width={4} height={4} rx={2} />
                                <rect y={70} width={4} height={4} rx={2} />
                                <rect x={23} y={70} width={4} height={4} rx={2} />
                                <rect x={45} y={70} width={4} height={4} rx={2} />
                                <rect x={68} y={70} width={4} height={4} rx={2} />
                                <rect x={90} y={70} width={4} height={4} rx={2} />
                                <rect x={113} y={70} width={4} height={4} rx={2} />
                                <rect x={135} y={70} width={4} height={4} rx={2} />
                                <rect x={158} y={70} width={4} height={4} rx={2} />
                                <rect x={181} y={70} width={4} height={4} rx={2} />
                                <rect x={203} y={70} width={4} height={4} rx={2} />
                                <rect y={88} width={4} height={4} rx={2} />
                                <rect x={23} y={88} width={4} height={4} rx={2} />
                                <rect x={45} y={88} width={4} height={4} rx={2} />
                                <rect x={68} y={88} width={4} height={4} rx={2} />
                                <rect x={90} y={88} width={4} height={4} rx={2} />
                                <rect x={113} y={88} width={4} height={4} rx={2} />
                                <rect x={135} y={88} width={4} height={4} rx={2} />
                                <rect x={158} y={88} width={4} height={4} rx={2} />
                                <rect x={181} y={88} width={4} height={4} rx={2} />
                                <rect x={203} y={88} width={4} height={4} rx={2} />
                                <rect y={105} width={4} height={4} rx={2} />
                                <rect x={23} y={105} width={4} height={4} rx={2} />
                                <rect x={45} y={105} width={4} height={4} rx={2} />
                                <rect x={68} y={105} width={4} height={4} rx={2} />
                                <rect x={90} y={105} width={4} height={4} rx={2} />
                                <rect x={113} y={105} width={4} height={4} rx={2} />
                                <rect x={135} y={105} width={4} height={4} rx={2} />
                                <rect x={158} y={105} width={4} height={4} rx={2} />
                                <rect x={181} y={105} width={4} height={4} rx={2} />
                                <rect x={203} y={105} width={4} height={4} rx={2} />
                                <rect y={123} width={4} height={4} rx={2} />
                                <rect x={23} y={123} width={4} height={4} rx={2} />
                                <rect x={45} y={123} width={4} height={4} rx={2} />
                                <rect x={68} y={123} width={4} height={4} rx={2} />
                                <rect x={90} y={123} width={4} height={4} rx={2} />
                                <rect x={113} y={123} width={4} height={4} rx={2} />
                                <rect x={135} y={123} width={4} height={4} rx={2} />
                                <rect x={158} y={123} width={4} height={4} rx={2} />
                                <rect x={181} y={123} width={4} height={4} rx={2} />
                                <rect x={203} y={123} width={4} height={4} rx={2} />
                                <rect y={140} width={4} height={4} rx={2} />
                                <rect x={23} y={140} width={4} height={4} rx={2} />
                                <rect x={45} y={140} width={4} height={4} rx={2} />
                                <rect x={68} y={140} width={4} height={4} rx={2} />
                                <rect x={90} y={140} width={4} height={4} rx={2} />
                                <rect x={113} y={140} width={4} height={4} rx={2} />
                                <rect x={135} y={140} width={4} height={4} rx={2} />
                                <rect x={158} y={140} width={4} height={4} rx={2} />
                                <rect x={181} y={140} width={4} height={4} rx={2} />
                                <rect x={203} y={140} width={4} height={4} rx={2} />
                            </g>
                        </svg>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default Index;
