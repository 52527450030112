import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    useQuery,
    useMutation,
} from 'react-query'
import axios from 'axios';
import randomstring from 'randomstring';
export default function AccountModal({
    open,
    setOpen,
    brand,
    isSubmitted,
    setIsSubmitted,
    showSuccessDialog,
    refetch }) {

    const [hasError, setHasError] = useState(false);
    const [name, setName] = useState('');
    const [sorting, setSorting] = useState('');

    useEffect(() => {
        if (!hasError && isSubmitted) {
            getBrandAction(brand)
        }
    }, [hasError, isSubmitted, setOpen])

    const createMutation = useMutation(data => {
        return axios.post(`${process.env.REACT_APP_STRAPI_DOMAIN}/brands`, data)
    })

    useEffect(() => {
        if (brand) {
            setName(brand.name)
            setSorting(brand.sorting)
        } else {
            setName('')
            setSorting('')
        }
    }, [brand])

    const updateMutation = useMutation(data => {
        return axios.put(`${process.env.REACT_APP_STRAPI_DOMAIN}/brands/${brand.id}`, data)
    })

    const updateBrand = async () => {
        try {
            let updateObj = {
                name,
                sorting
            };
            const result = await updateMutation.mutateAsync(updateObj)
            if (result) {
                refetch();
                setOpen(false);
                showSuccessDialog('Brand Successfully Saved');
            }
        } catch (error) {
            console.error(error)
        }
    }

    const createBrand = async () => {
        try {
            const result = await createMutation.mutateAsync({ name, sorting })
            if (result) {
                refetch();
                setOpen(false);
                showSuccessDialog('Brand Successfully Created');
            }
        } catch (error) {
            console.error(error)
        }
    }

    const DEFAULT_ERROR_MSG = 'Invalid field 資料有誤，請檢查此欄'

    const isError = (field, value, isMandatory = false) => {
        if (isMandatory && isSubmitted) {
            if (!value && !hasError) {
                setHasError(true);
            }
            return !value;
        }
    }

    const getBrandAction = (brand) => {
        if (brand) {
            return updateBrand();
        }
        return createBrand();
    }

    const attemptSubmission = () => {
        setHasError(false);
        setIsSubmitted(true);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-end justify-center h-5/6 pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="py-5 px-5 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <h1 className="text-gray-800 font-bold text-lg">{brand ? 'Update Brand' : 'Create Brand'}</h1>


                            <div className="flex flex-col mt-6">
                                <label className="mb-3 text-base leading-none text-gray-600">Name</label>
                                <input value={name} onInput={e => setName(e.target.value)} type="text" placeholder="Enter name" tabIndex={0} aria-label="Enter name" className={`${isError('name', name, true) ? 'border-red-500' : 'border-gray-200'} w-full bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded`} />
                                {isError('name', name, true) && <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {DEFAULT_ERROR_MSG}
                                </span>}
                            </div>

                            <div className="flex flex-col mt-6">
                                <label className="mb-3 text-base leading-none text-gray-600">Display Ordering</label>
                                <input value={sorting} onInput={e => setSorting(e.target.value)} type="number" placeholder="Enter ordering" tabIndex={0} aria-label="Enter ordering" className={`${isError('sorting', sorting, true) ? 'border-red-500' : 'border-gray-200'} w-full bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded`} />
                                {isError('sorting', sorting, true) && <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {DEFAULT_ERROR_MSG}
                                </span>}
                            </div>

                            <div className="flex items-center justify-between mt-10">
                                <button onClick={() => setOpen(false)} className=" py-3.5 w-full text-gray-600 focus:outline-none hover:opacity-90 text-sm font-semibold border border-gray-600 rounded">Dismiss</button>
                                <div className="pl-2 w-full">
                                    <button onClick={() => attemptSubmission()} className=" py-3.5 w-full text-white focus:outline-none hover:opacity-90 text-sm font-semibold border border-green-700 bg-green-600 rounded">{brand ? 'Update' : 'Create'}</button>
                                </div>
                            </div>

                        </div>

                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}