
import React, { Component } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

function Index() {

    return (
        <>
            {/* To use this card, please use [6x6] grid from Grids. */}
            {/* Card code block start */}

            <div className="border h-screen w-full mx-auto bg-white border-gray-400 rounded">
                <div className="flex justify-center border-b border-gray-200 mt-10">
                    <div className="flex pb-6">
                        <div className="h-60 w-60">
                            <img src="https://jebsenconsumerproductsservicecentrehk.simplybook.asia/uploads/jebsenconsumerproductsservicecentrehk/image_files/preview/dc214d40a34bca1b12f34b793bd628fa.jpg" alt className="h-full w-full object-cover overflow-hidden" />
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="flex justify-center  pb-6">
                        <div className="pl-4">
                            <p className="text-center text-4xl text-gray-800">請選擇服務<br />Please Select Service</p>
                        </div>
                    </div>
                    <div>
                        <div className="mt-4 flex w-full justify-center items-center">
                            <div className="flex w-full justify-center items-center">
                                <button className="shadow-lg w-80 h-40 bg-white transition duration-150 ease-in-out rounded border-2 border-jebsen-default text-jebsen-default px-16 py-4 text-3xl">維修<br />Repair</button>
                            </div>
                            <div className="flex w-full justify-center items-center">
                                <button className="shadow-lg w-80 h-40 bg-white transition duration-150 ease-in-out rounded border-2 border-jebsen-default text-jebsen-default px-16 py-4 text-3xl">取件<br />Collection</button>
                            </div>
                        </div>
                        <div className="mt-10 flex w-full justify-center items-center">
                            <div className="flex w-full justify-center items-center">
                                <button className="shadow-lg w-80 h-40 bg-white transition duration-150 ease-in-out rounded border-2 border-jebsen-default text-jebsen-default px-16 py-4 text-3xl">購買<br />Purchase</button>
                            </div>
                            <div className="flex w-full justify-center items-center">
                                <button className="shadow-lg w-80 h-40 bg-white transition duration-150 ease-in-out rounded border-2 border-jebsen-default text-jebsen-default px-16 py-4 text-3xl">換領<br />Redemption</button>
                            </div>
                        </div>
                        <div className="mt-10 flex w-full justify-center items-center">
                            <div className="flex w-full justify-center items-center">
                                <button className="shadow-lg w-80 h-40 bg-white transition duration-150 ease-in-out rounded border-2 border-jebsen-default text-jebsen-default px-16 py-4 text-3xl">更換電池<br />Battery Change</button>
                            </div>
                        </div>
                        <div className="mt-10 flex w-full justify-center items-center">
                            <div className="flex w-full justify-center items-center">
                                <button className="shadow-lg w-40 h-24 bg-white transition duration-150 ease-in-out rounded border-2 border-gray-600 text-gray-600 text-3xl">返回<br />Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Card code block end */}
        </>
    );
}
export default Index;
