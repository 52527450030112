import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Bookings from './Bookings';
import Config from './components/Config';
import BookingList from './components/BookingList';
import AccountList from './components/AccountList';
import Timeslot from './components/Timeslot';
import Brand from './components/BrandList';
import Login from './components/Login';

import Dispenser from './queue/Dispenser';
import Service from './queue/Service';
import Number from './queue/Number';
import Walkin from './queue/Walkin';

import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

// Create a client
const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>

      <Router>
        <Switch>
          <Route path="/config">
            <Bookings Content={Config} pageTitle={'Configurations'} />
          </Route>
          <Route path="/timeslots">
            <Bookings Content={Timeslot} pageTitle={'Timeslots'} />
          </Route>
          <Route path="/bookings">
            <Bookings Content={BookingList} pageTitle={'Bookings'} />
          </Route>
          <Route path="/accounts">
            <Bookings Content={AccountList} pageTitle={'Accounts'} />
          </Route>
          <Route path="/brands">
            <Bookings Content={Brand} pageTitle={'Brands'} />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/">
            <App />
          </Route>
          <Route path="/booking-app">
            <App />
          </Route>
          <Route path="/queuing-dispenser">
            <Dispenser />
          </Route>
          <Route path="/queuing-service">
            <Service />
          </Route>
          <Route path="/queuing-reserved">
            <Number />
          </Route>
          <Route path="/queuing-walkin">
            <Walkin />
          </Route>
        </Switch>
      </Router>
    </QueryClientProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
