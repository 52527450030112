import './App.css';
import React, { useState, Fragment, useEffect } from "react";
import { addHours, setHours, getHours, setMinutes, getMinutes, parse, getISODay, getDate, startOfWeek, endOfWeek, eachDayOfInterval, isToday, format, addWeeks, isSameDay, isAfter, isFuture } from 'date-fns';
import { animateScroll } from 'react-scroll';
import Cookies from 'universal-cookie';
import { random, orderBy, upperCase, get, toString, pick } from 'lodash';
import randomstring from 'randomstring';
import {
  useQuery,
  useMutation,
} from 'react-query'
import axios from 'axios';
import { Datepicker } from "./components/Datepicker";
import { eachWeekOfInterval } from 'date-fns'
import { toDate } from 'date-fns-tz';
import qs from 'qs'
import bg from './jebsen-bg.jpeg';
import { useLocation, useHistory } from 'react-router-dom'

/* IMPORTANT */
/* IMPORTANT */
/* IMPORTANT */
/* DYSON SLOTS is now used as "additional slots" and will be used by PCCW user */

// TODO NO HARD CODE BRAND ID
const HARD_CODE_DYSON_BRAND_ID = 5;
const DEFAULT_TIMEOUT = 5000;
const DEFAULT_COUNTRY_CODE = '852';
const cookies = new Cookies();
const BOOKING_IN_ADVANCE_BY_HOURS = 12;

const weekDaysMap = [
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
  'SUN'
]

const colorArray = [
  'indigo',
  'yellow',
  'green',
  'blue',
  'pink'
]

const DEFAULT_ERROR_MSG = 'Invalid field 資料有誤，請檢查此欄'
const DEFAULT_PHONE_ERROR_MSG = 'Invalid phone number 錯誤的電話號碼'
const DEFAULT_EXCEED_LIMIT_ERROR = 'Already exceeded booking limit 已超出預約上限'
const DEFAULT_EMAIL_ERROR_MSG = 'Invalid email address 錯誤的電郵地址'
const DEFAULT_ALERT = 'We are now submitting your application form, please wait patiently. 正在提交，請稍候。'
const DEFAULT_CANCEL_BOOKING_ALERT = 'Booking successfully canceled. 預約已成功取消。'
const DEFAULT_SLOT_LIMIT_REACHED = 'Booking limit for this session are reached, please select another timeslot. 已達預約上限，請選擇另一節時間。'

function App({ noBanner = false, showOccupied = false, accountRole = 'PUBLIC' }) {
  const location = useLocation()
  const page = qs.parse(location.search, { ignoreQueryPrefix: true }).page || '';

  // config data init
  const [timeslotReserve, setTimeslotReserve] = useState(null);
  const [isMaintenanceOn, setIsMaintenanceOn] = useState(false);
  const [termsAndConditionsFile, setTermsAndConditionsFile] = useState(null);

  const [isExceededReserveLimit, setIsExceededReserveLimit] = useState(false);
  const [submittedAlert, setSubmittedAlert] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedWeek, setSelectedWeek] = useState(1);
  const [bookedHistories, setBookedHistories] = useState([]);
  const [myFetchedBookings, setMyFetchedBookings] = useState([]);

  const [showStep, setShowStep] = useState(page !== 'my-booking');
  const [showMyBooking, setShowMyBooking] = useState(page === 'my-booking');
  const [service, setService] = useState('');
  const [serviceCode, setServiceCode] = useState('');

  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [confirmedTimeSlot, setConfirmedTimeSlot] = useState(null);

  const [bookingId, setBookingId] = useState(false);
  const [qrCode, setQrCode] = useState(false);
  const [alertText, setAlertText] = useState(DEFAULT_ALERT)

  // form

  const [hasError, setHasError] = useState(false);
  const [date, setDate] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [brand, setBrand] = useState('');
  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_CODE);
  const [phone, setPhone] = useState('');
  const [serviceNo, setServiceNo] = useState('');
  const [symptom, setSymptom] = useState('');
  const [productName, setProductName] = useState('');
  const [purchasedAt, setPurchasedAt] = useState(new Date());

  const [isAgreedTnc, setIsAgreedTnc] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [weekIntervals, setWeekIntervals] = useState(null)

  const intervals = eachDayOfInterval({
    start: startOfWeek(addWeeks(new Date(), selectedWeek - 1)),
    end: endOfWeek(addWeeks(new Date(), selectedWeek - 1))
  })

  useEffect(() => {
    if (!hasError && isSubmitted) {
      submitForm();
    }
  }, [hasError, isSubmitted])

  useEffect(() => {
    if (bookedHistories.length) {
      const resyncBookingQuery = qs.stringify({ _where: { bookingId_in: bookedHistories.map(x => x.bookingId) } });
      fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/bookings?${resyncBookingQuery}`).then((res) =>
        res.json()
      ).then(data => {
        setMyFetchedBookings(data);
        return data;
      })
    }
  }, [bookedHistories])



  // Queries
  const brandQuery = useQuery('brands', () =>
    fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/brands`).then((res) =>
      res.json()
    ),
    { refetchOnWindowFocus: false })

  const timeslotQuery = useQuery('timeslots', () =>
    fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/timeslots`).then((res) =>
      res.json()
    ),
    { refetchOnWindowFocus: false })

  useQuery('configurations', () =>
    fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/configurations`).then((res) =>
      res.json()
    ).then(data => {
      setTimeslotReserve(data[0].timeslotReserve);
      setWeekIntervals(eachWeekOfInterval({
        start: new Date(),
        end: addWeeks(new Date(), data[0].aheadOfWeek)
      }))
      setIsMaintenanceOn(data[0].isMaintenanceOn);
      setTermsAndConditionsFile(data[0].termsAndConditionsFile);

      return data;
    }),
    { refetchOnWindowFocus: false })

  const mutation = useMutation(data => {
    return axios.post(`${process.env.REACT_APP_STRAPI_DOMAIN}/bookings`, data)
  })

  const getTimeslot = (interval, timeslots) => {
    const specificDays = [];

    // find out specific day and get schedules
    let schedules = timeslots.filter(timeslot => {
      const targetBookings = timeslot.bookings.filter(booking => {
        return isSameDay(new Date(interval), new Date(booking.bookingAt)) && booking.status === 'OPEN' && (accountRole === 'PCCW_USER' ? !!booking.isBookedViaAddQuota: !booking.isBookedViaAddQuota)
      })
      let remainSlots = timeslot.slots - targetBookings.length;
      if (accountRole === 'PCCW_USER') {
        remainSlots = timeslot.dysonSlots - targetBookings.length;
      }
      let hasSpecificDay = timeslot.type === 'SPECIFIC' && isSameDay(interval, parse(timeslot.date, 'yyyy-MM-dd', new Date()));

      if (hasSpecificDay) {
        specificDays.push(getISODay(interval))
      }

      return (weekDaysMap[getISODay(interval) - 1] === timeslot.type
        || hasSpecificDay) && (remainSlots > 0 || accountRole !== 'PUBLIC')
    })

    // eliminates schedules of normal day to replace with specific day schedules
    schedules = schedules.filter(schedule => {

      let startAtTime = parse(schedule.startAt, 'HH:mm:ss.SSS', new Date());
      let startAtDateTime = setHours(setMinutes(interval, getMinutes(startAtTime)), getHours(startAtTime));

      // 12+ hours schedule slot cannot be viewed (i.e. no today's booking for public)
      if (addHours(new Date(), BOOKING_IN_ADVANCE_BY_HOURS) > startAtDateTime && accountRole === 'PUBLIC') {
        return false;
      }

      // invalid schedule. this shows up because of holiday schedule
      if (schedule.startAt === schedule.endAt) {
        return false;
      }

      if (isAfter(new Date(), startAtDateTime)) {
        return false;
      }

      return (specificDays.includes(getISODay(parse(schedule.date, 'yyyy-MM-dd', new Date()))) && schedule.type === 'SPECIFIC') || !specificDays.length
    })

    // format them
    let maxTimeSlots = 0;
    schedules = schedules.map(schedule => {

      let startAtTime = parse(schedule.startAt, 'HH:mm:ss.SSS', new Date());
      let startAtDateTime = setHours(setMinutes(interval, getMinutes(startAtTime)), getHours(startAtTime));

      let endAtTime = parse(schedule.endAt, 'HH:mm:ss.SSS', new Date());
      let endAtDateTime = setHours(setMinutes(interval, getMinutes(endAtTime)), getHours(endAtTime));

      const targetBookings = schedule.bookings.filter(booking => {
        return isSameDay(new Date(interval), new Date(booking.bookingAt)) && booking.status === 'OPEN' && (accountRole === 'PCCW_USER' ? !!booking.isBookedViaAddQuota: !booking.isBookedViaAddQuota)
      })

      let remainSlots = schedule.slots - targetBookings.length;
      maxTimeSlots = schedule.slots;
      if (accountRole === 'PCCW_USER') {
        remainSlots = schedule.dysonSlots - targetBookings.length;
        maxTimeSlots = schedule.dysonSlots;
      }

      let res = {
        ...schedule,
        startAt: startAtDateTime,
        endAt: endAtDateTime,
        remainSlots,
        maxTimeSlots
      };

      console.log('timeslots', res);
      return res
    })

    // sort schedules
    return schedules.sort((a, b) => {
      return new Date(a.startAt) - new Date(b.startAt);
    });
  }

  const updateMutation = useMutation(data => {
    return axios.put(`${process.env.REACT_APP_STRAPI_DOMAIN}/bookings/${data.bookingSystemId}`, data)
  })

  const updateSlotMutation = useMutation(data => {
    return axios.put(`${process.env.REACT_APP_STRAPI_DOMAIN}/timeslots/${data.timeslotId}`, {
      bookings: [
        ...data.bookings.map(booking => booking.id),
        data.bookingId
      ]
    })
  })

  const services = [
    {
      name: "Repair 維修",
      code: "REPAIR",
      desc: "",
      suffix: "",
    },
    {
      name: "Collection 取件",
      code: "COLLECTION",
      desc: "Please provide CS statement during collection. 領取維修時必須出示售後服務單。",
      suffix: "",
    },
    {
      name: "Purchase 購買",
      code: "PURCHASE",
      desc: "", 
      suffix: "",// Shop Tools and Accessories (購買配件)
      // misc: <div><p className="text-base font-semibold leading-4 pt-1 underline cursor-pointer text-gray-500">
      //   <a href="https://corpsolutions.jebsenconsumer.com/pages/brand-sub-page">English URL</a></p>
      //   <p className="text-base font-semibold leading-4 pt-1 underline cursor-pointer text-gray-500">
      //     <a href="https://corpsolutions.jebsenconsumer.com/zh-tw/pages/brand-sub-page">中文連結</a></p></div>,
    },
  ]

  const validEmail = new RegExp(
    '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
  );

  const validPhone = new RegExp(
    '^852[4-9][0-9]{7}$|^853[4-9][0-9]{7}$|^86[0-9]{11}$'
  );

  const setStep = (stepNo, fn, name) => {
    setCurrentStep(stepNo);

    if (fn) {
      fn(name);
      animateScroll.scrollToBottom({});
    }
  }

  const filterBooked = (bookedHistories) => {
    return bookedHistories.filter(booked => {
      const m = myFetchedBookings.filter(x => {
        return x.bookingId === booked.bookingId
      })
      const bookingAt = get(m, '0.bookingAt', null);
      const timeCondition = bookingAt ? isFuture(new Date(bookingAt)) : false
      return m.length && get(m, '0.status', '') === 'OPEN' && timeCondition
    })
  }

  const isError = (field, value, isMandatory = false) => {
    if (field === 'email' && isSubmitted) {
      if (!validEmail.test(value) && !hasError) {
        setHasError(true);
      }
      return !validEmail.test(value);
    }

    if (field === 'phone' && isSubmitted) {
      if (isSubmitted && isExceededReserveLimit) {
        return true;
      }
      if (!validPhone.test(`${countryCode}${phone}`) && !hasError) {
        setHasError(true);
      }
      return !validPhone.test(`${countryCode}${phone}`);
    }

    if (isMandatory && isSubmitted) {
      if (!value && !hasError) {
        setHasError(true);
      }
      return !value;
    }
  }

  const reset = () => {
    setCurrentStep(1);
    setService('');
    setConfirmedTimeSlot(null);
    setSelectedTimeSlot(null);
    setName('');
    setEmail('');
    setBrand('');
    setDate(null);
    setCountryCode(DEFAULT_COUNTRY_CODE);
    setPhone('');
    setServiceNo('');
    setSymptom('');
    setProductName('');
    setPurchasedAt(new Date());
    setIsAgreedTnc(true);
    setIsSubscribed(true);
    setIsSubmitted(false);
  }

  // const showMyBookingPage = () => {
  //   reset();
  //   setShowStep(false);

  //   let submittedHistories = cookies.get('formSubmitted');
  //   if (!submittedHistories) {
  //     submittedHistories = { submitted: [] };
  //   }
  //   setBookedHistories(submittedHistories.submitted);
  //   setShowMyBooking(true);
  // }

  useEffect(() => {
    if (showMyBooking) {
      reset();
      setShowStep(false);

      let submittedHistories = cookies.get('formSubmitted');
      if (!submittedHistories) {
        submittedHistories = { submitted: [] };
      }
      setBookedHistories(submittedHistories.submitted);
    }
  }, [showMyBooking])

  const setServiceStep = (service) => {
    setStep(2, setService, service.name)
    setServiceCode(service.code);
  }

  const showBookingForm = () => {
    reset();
    setShowStep(true);
    setShowMyBooking(false);
  }

  const submitForm = () => {
    const maxSlots = (accountRole === 'PCCW_USER' ? confirmedTimeSlot.dysonSlots : confirmedTimeSlot.slots);
    const slotCheckQuery = qs.stringify({ _where: { bookingAt_eq: date, status_eq: 'OPEN', isBookedViaAddQuota_eq: accountRole === 'PCCW_USER' } });
    fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/bookings?${slotCheckQuery}`)
      .then((res) => res.json())
      .then(overallBooked => {
        if (overallBooked.length >= maxSlots && (accountRole === 'PCCW_USER' || accountRole === 'PUBLIC')) {
          setSubmittedAlert(true);
          setAlertText(DEFAULT_SLOT_LIMIT_REACHED);

          setTimeout(() => {
            setSubmittedAlert(false);
            setAlertText(DEFAULT_ALERT);
          }, DEFAULT_TIMEOUT)
          return;
        }

        const bookingAtDate = toDate(date, { timeZone: 'Asia/Hong_Kong' })

        if (isAgreedTnc) {

          // phone verification per week
          const query = qs.stringify({ _where: { phone_contains: phone, created_at_gte: startOfWeek(new Date()), created_at_lte: endOfWeek(new Date()), status_eq: 'OPEN' } });
          fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/bookings?${query}`)
            .then((res) => res.json())
            .then(bookedByOwnPhone => {

              if (bookedByOwnPhone.length >= timeslotReserve) {
                setIsExceededReserveLimit(true)
              } else if (!hasError) {

                setSubmittedAlert(true);

                let submittedHistories = cookies.get('formSubmitted');
                let bookingId = `${upperCase(serviceCode).charAt(0)}${format(new Date(), 'dd')}${randomstring.generate({ length: 5, charset: 'alphanumeric', capitalization: 'uppercase' })}`;
                let qrCode = `${format(new Date(), 'yyyyMMddHH')}${randomstring.generate({ length: 6, charset: 'alphanumeric', capitalization: 'lowercase' })}`;

                setBookingId(bookingId)
                setQrCode(qrCode)
                
                const bookingAtDate = toDate(date, { timeZone: 'Asia/Hong_Kong' });
                mutation.mutate({ isBookedViaAddQuota: accountRole === 'PCCW_USER', bookedByRole: accountRole, qrCode, serviceType: serviceCode, name, email, countryCode, phone, brand, purchasedAt: serviceCode === 'REPAIR' ? purchasedAt : null, symptom, serviceNumber: serviceNo, productName, isSubscribed, timeslot: confirmedTimeSlot.id, bookingId, bookingAt: bookingAtDate }, {

                  onSuccess: (data) => {
                    if (data) {
                      let _bookingSystemId = get(data, 'data.id', null);

                      fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/timeslots/${confirmedTimeSlot.id}`)
                        .then((res) => res.json())
                        .then(updatedTimeslot => {

                          updateSlotMutation.mutate({
                            timeslotId: updatedTimeslot.id,
                            bookings: updatedTimeslot.bookings,
                            bookingId: get(data, 'data.id', null)
                          }, {

                            onSuccess: () => {
                              if (!submittedHistories) {
                                submittedHistories = { submitted: [] };
                              }

                              submittedHistories.submitted.push({
                                service,
                                confirmedTimeSlot: pick(confirmedTimeSlot, ['id', 'startAt', 'endAt']),
                                bookingId,
                                bookingSystemId: _bookingSystemId
                              })

                              cookies.set('formSubmitted', JSON.stringify(submittedHistories), { path: '/' });

                              timeslotQuery.refetch();

                              setTimeout(() => {
                                setSubmittedAlert(false);
                                setCurrentStep(4);
                                setSelectedTimeSlot(null);
                              }, DEFAULT_TIMEOUT)

                              return;
                            }
                          })

                        })


                    }
                  },

                })

              }

            })
        }

        return;

      })
  }

  const cancelBooking = (bookingSystemId) => {
    updateMutation.mutate({ status: 'CANCELED', bookingSystemId }, {

      onSuccess: (data) => {
        if (data) {
          setSubmittedAlert(true)
          setAlertText(DEFAULT_CANCEL_BOOKING_ALERT);
          setTimeout(() => {
            setSubmittedAlert(false);
            setAlertText(DEFAULT_ALERT);
            let submittedHistories = cookies.get('formSubmitted');
            submittedHistories.submitted = submittedHistories.submitted.filter(submittedHistory => {
              return toString(submittedHistory.bookingSystemId) !== toString(bookingSystemId)
            })
            cookies.set('formSubmitted', JSON.stringify(submittedHistories), { path: '/' });
            window.location.reload(false)
          }, DEFAULT_TIMEOUT)

        }
      }
    })
  }

  const attemptSubmission = () => {
    if (submittedAlert) return;
    setHasError(false);
    setIsSubmitted(true);
  }

  const getTextColor = (stepNo) => {
    if (currentStep === stepNo) return 'text-indigo-700';
    return currentStep > stepNo ? 'text-green-600' : 'text-gray-900';
  }

  const getBgColor = (stepNo) => {
    if (currentStep === stepNo) return 'bg-indigo-700';
    return currentStep > stepNo ? 'bg-green-600' : 'bg-gray-50';
  }

  const getProgressLine = (stepNo) => {
    if (currentStep >= stepNo) return <div className="mx-8 sm:block hidden ">
      <svg width="47" height="2" viewBox="0 0 47 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="1" x2="47" y2="1" stroke="#059669" strokeWidth="2" />
      </svg>
    </div>
    return <div className="mx-8 md:mt-0 mt-4 sm:block hidden">
      <svg width="47" height="2" viewBox="0 0 47 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="1" x2="47" y2="1" stroke="#9CA3AF" strokeWidth="2" strokeDasharray="5 5" />
      </svg>
    </div>
  }

  return (

    <div>

      {!noBanner && <div className="w-full h-screen relative">

        <img className="absolute w-full h-full z-0 object-cover" src={bg} />
        <div className="relative z-20">
          <div className="flex items-center justify-end  py-10 md:px-20 px-4">
            {/* <div className="cursor-pointer">
              <svg width={64} height={40} viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 21.3189C0.0414124 31.586 8.42031 40 18.7135 40C29.0319 40 37.4263 31.6199 37.4263 21.3189V18.6817C37.4263 14.4401 40.8833 10.989 45.1321 10.989C49.3815 10.989 52.8379 14.4401 52.8379 18.6817C52.8379 19.8954 53.8237 20.8796 55.0395 20.8796H61.6445C62.8609 20.8796 63.8462 19.8954 63.8462 18.6817C63.8462 8.38072 55.4518 0 45.1327 0C34.8142 0 26.4192 8.38072 26.4192 18.6817V21.3189C26.4192 25.5611 22.9622 29.011 18.7141 29.011C14.4653 29.011 11.0083 25.5611 11.0083 21.3189C11.0083 20.1052 10.0224 19.121 8.80662 19.121H2.20166C0.985862 19.1216 0 20.1052 0 21.3189Z"
                  fill="white"
                />
              </svg>
            </div> */}
            <button onClick={() => setShowMyBooking(true)} className="flex items-center justify-center text-base text-center text-jebsen-default font-semibold md:px-7 px-4 md:py-4 py-3 hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-white-700 focus:outline-none bg-white rounded-lg">My Bookings 我的預約</button>
          </div>

          <p className="md:hidden mt-40 text-base md:px-0 px-4 font-medium tracking-widest leading-none text-center text-white uppercase">jebsen consumer</p>
          <h1 className="md:hidden px-4 text-2xl font-bold text-center mt-6 text-white uppercase">Quality product</h1>
          <h1 className="md:hidden px-4 text-2xl font-bold text-center text-white uppercase">for betting living</h1>
          <div className="flex items-center justify-center w-full md:mt-12 mt-6 md:pb-32 pb-24">
          </div>
        </div>
      </div>}

      <div className="flex items-center w-full xl:justify-end justify-center relative z-20">

        {submittedAlert && <div className="mx-auto md:w-4/12 w-full bg-white shadow-lg rounded flex pr-4 absolute left-0 sm:left-auto right-0 md:top-0 top-24 mt-20 dark:bg-gray-800 sm:mb-0 animate-fade-in-up">
          <div role="alert" aria-label="Close" className="cursor-pointer absolute right-0 mr-2 mt-2 text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-500 transition duration-150 ease-in-out" onClick={() => setSubmittedAlert(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={20} height={20} viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" />
              <line x1={18} y1={6} x2={6} y2={18} />
              <line x1={6} y1={6} x2={18} y2={18} />
            </svg>
          </div>
          <div className="px-5 border-r border-gray-300 dark:border-gray-700 flex items-center justify-center text-green-400">
            <svg className="animate-spin h-8 w-8 text-red-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          <div className="flex flex-col justify-center pl-4 py-4">
            <p className="text-base text-gray-800 dark:text-gray-100 font-semibold">Notifcation 通知</p>
            <p className="text-sm text-gray-600 dark:text-gray-400 font-normal">{alertText}</p>
          </div>
        </div>}
        <div className={`${!noBanner ? '-mt-40' : ''} w-full`}>
          <div className={`sm:mt-6 ${showMyBooking ? 'bg-gray-200' : 'bg-gray-50'} rounded py-6 xl:px-24 px-4 flex items-center justify-center`}>
            {!showStep && <div className="container mx-auto w-5/6 sm:w-2/3 h-full">
              {/* Code block starts */}
              <div className="w-full bg-white dark:bg-gray-800 py-5 flex items-center justify-between px-5 sm:px-10 shadow rounded-t">
                <div className="mb-0">
                  <h2 className="text-gray-800 dark:text-gray-100 text-2xl font-bold">My Bookings 我的預約</h2>
                  <p className="font-normal text-base dark:text-gray-400 text-gray-600 mt-1">Total <b>{filterBooked(bookedHistories).length}</b> bookings are found at the moment.</p>
                  <p className="font-normal text-base dark:text-gray-400 text-gray-600 mt-1">目前已找到 共 <b>{filterBooked(bookedHistories).length}</b> 個預約。</p>
                </div>
                <button onClick={() => showBookingForm()} className="hover:bg-indigo-700 m-4 font-normal bg-jebsen-default transition duration-150 ease-in-out focus:bg-jebsen-default focus:outline-none rounded text-white px-4 py-2 text-base">New Booking <p>新預約</p></button>
              </div>
              {/* Code block ends */}
            </div>}
            {showStep && <div className="grid sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-7 items-center">
              <div className="flex items-center">
                <div className={`w-8 h-8 ${getBgColor(1)} rounded-full flex items-center justify-center`}>
                  <p className={`text-base ${getBgColor(1) !== 'bg-gray-50' ? 'text-white' : 'text-black'}`}>1</p>
                </div>
                <div className="flex justify-between items-center pb-2 flex-col">
                  <p className={`text-base leading-4 ml-6 md:ml-3 lg:ml-6 ${getTextColor(1)}`}>Service 服務 {service && <p className="text-xs font-bold text-gray-800 w-28">{service}</p>}</p>
                </div>
              </div>
              {getProgressLine(2)}
              <div className="flex items-center md:mt-0 mt-4">
                <div className={`w-8 h-8 ${getBgColor(2)} rounded-full flex items-center justify-center`}>
                  <p className={`text-base ${getBgColor(2) !== 'bg-gray-50' ? 'text-white' : 'text-black'}`}>2</p>
                </div>
                <div className="flex justify-between items-center pb-2 flex-col">
                  <p className={`text-base leading-4 ml-6 md:ml-3 lg:ml-6 ${getTextColor(2)}`}>Time 時間 {confirmedTimeSlot && <p className="text-xs font-bold text-gray-800 w-28">{format(confirmedTimeSlot.startAt, 'd MMMM yyyy, hh:mm a')} - {format(confirmedTimeSlot.endAt, 'hh:mm a')}</p>}</p>
                </div>
              </div>
              {getProgressLine(3)}
              <div className="flex items-center md:mt-0 mt-4">
                <div className={`w-8 h-8 ${getBgColor(3)} rounded-full flex items-center justify-center`}>
                  <p className={`text-base ${getBgColor(3) !== 'bg-gray-50' ? 'text-white' : 'text-black'}`}>3</p>
                </div>
                <div className="flex justify-between items-center pb-2 flex-col">
                  <p className={`text-base leading-4 ml-6 md:ml-3 lg:ml-6 ${getTextColor(3)}`}>Application 表格{currentStep > 3 && <p className="text-xs font-bold text-gray-800 w-28">Submitted 已遞交</p>}</p>
                </div>
              </div>
              {getProgressLine(4)}
              <div className="flex items-center lg:mt-0 mt-4">
                <div className={`w-8 h-8 ${getBgColor(4)} rounded-full flex items-center justify-center`}>
                  <p className={`text-base ${getBgColor(4) !== 'bg-gray-50' ? 'text-white' : 'text-black'}`}>4</p>
                </div>
                <p className="text-base leading-4 ml-6 md:ml-3 lg:ml-6 text-gray-900">Success 預約成功{currentStep > 3 && <p className="text-xs font-bold text-gray-800 w-30">Booking Confirmed 已確認</p>}</p>
              </div>
            </div>}
          </div>



          <div className={`bg-gray-200 ${!showMyBooking ? 'md:p-16 p-8 mb-10' : ''} lg:flex justify-center md:px-0 px-4 items-center`}>

            {showMyBooking && <Fragment><div className="container justify-start mx-auto w-5/6 sm:w-2/3 h-full">

              <div className="flex items-center justify-center py-8">
                <div class="grid grid-cols-1 md:grid-cols-4 gap-2">
                  {filterBooked(bookedHistories).sort(function (a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(a.confirmedTimeSlot.startAt) - new Date(b.confirmedTimeSlot.startAt)
                  }).map((bookedHistory, index) => {
                    return (
                      <div key={index} className="animate-fade-in-down m-3 max-w-sm shadow">
                        <div className={`bg-${colorArray[random(0, 4)]}-500 bg-opacity-5 rounded-tl rounded-tr px-4 sm:px-20 pt-2`}>
                          <img className="object-cover" src="https://i.ibb.co/7yDQCDt/Illustration.png" alt />
                        </div>
                        <div className="bg-white dark:bg-gray-800 rounded-b sm:p-6 p-4">
                          <div className="flex items-center">
                            <div className="pr-5">
                              <p className="w-8 text-sm font-semibold leading-none text-center text-gray-800 dark:text-gray-100">{format(new Date(bookedHistory.confirmedTimeSlot.startAt), 'EEE')}</p>
                              <p className="w-7 text-xl font-semibold leading-5 pt-1 text-center text-gray-800 dark:text-gray-100">{format(new Date(bookedHistory.confirmedTimeSlot.startAt), 'dd')}</p>
                            </div>
                            <div className="pl-4 border-l border-gray-200">
                              <p className="text-sm font-semibold leading-none text-gray-800 dark:text-gray-100">{bookedHistory.service}</p>
                              <p className="mt-2 text-center text-sm leading-none text-gray-800 dark:text-gray-100"><span className="text-red-600">{bookedHistory.bookingId}</span></p>
                            </div>
                          </div>
                          <div className="flex items-center pt-8">
                            <div className="pr-4">
                              <div className="w-8 h-8 bg-gray-100 dark:bg-gray-700 rounded flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler text-indigo-700 dark:text-indigo-600 icon-tabler-calendar" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <rect x={4} y={5} width={16} height={16} rx={2} />
                                  <line x1={16} y1={3} x2={16} y2={7} />
                                  <line x1={8} y1={3} x2={8} y2={7} />
                                  <line x1={4} y1={11} x2={20} y2={11} />
                                </svg>
                              </div>
                            </div>
                            <div>
                              <p className="text-sm font-medium leading-none text-gray-800 dark:text-gray-100">{format(new Date(bookedHistory.confirmedTimeSlot.startAt), 'd MMMM yyyy')}</p>
                              <p className="pt-2 text-xs leading-3 text-gray-500 dark:text-gray-400">{format(new Date(bookedHistory.confirmedTimeSlot.startAt), 'hh:mm a')} - {format(new Date(bookedHistory.confirmedTimeSlot.endAt), 'hh:mm a')}</p>
                            </div>
                          </div>
                          <div className="flex items-center pt-5">
                            <div className="pr-4">
                              <div className="w-8 h-8 bg-gray-100 dark:bg-gray-700 rounded flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler text-indigo-700 dark:text-indigo-600 icon-tabler-map-pin" width={20} height={20} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <circle cx={12} cy={11} r={3} />
                                  <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                                </svg>
                              </div>
                            </div>
                            <div>
                              <a target="_blank" href="https://hk.jebsenconsumer.com/en/service-centre-information"><p className="text-jebsen-default underline text-sm font-medium leading-none text-gray-800 dark:text-gray-100">Jebsen Consumer<p className="mt-1">Products Service Centre</p></p></a>
                            </div>

                          </div>
                          <div className="px-4 mt-8 flex">
                            <button onClick={() => cancelBooking(bookedHistory.bookingSystemId)} className={`${submittedAlert ? 'cursor-not-allowed opacity-60 bg-gray-500' : 'bg-red-700 cursor-pointer'} text-white py-2 w-full focus:outline-none hover:opacity-90 text-md font-semibold border rounded`}>{submittedAlert ? 'Loading 加載中' : 'Cancel 取消預約'}</button>
                          </div>
                        </div>
                      </div>)
                  })}
                </div></div>
            </div></Fragment>}

            {!showMyBooking && <Fragment>

              {currentStep === 1 && services.map(service => {
                return (
                  <div key={service.name} className="animate-fade-in-up w-full h-80 md:w-96 mt-10 lg:mt-0 py-10 pl-10 pr-8 bg-white shadow border rounded-xl mr-6">
                    <h1 className="text-2xl font-medium text-gray-800">{service.name}</h1>
                    <div className="h-28">
                      <p className="text-base leading-6 pt-9 text-gray-800">{service.desc}</p>{service.misc}
                    </div>
                    <div className="lg:mt-5 mt-10 sm:flex sm:justify-center lg:justify-start">

                      <div className="rounded-md shadow">
                        <a
                          className="cursor-pointer w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-jebsen-default hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                          onClick={() => setServiceStep(service)}
                        >
                          Select 選擇
                        </a>
                      </div>
                    </div>
                  </div>
                )
              })}

              {currentStep === 2 && <>
                <div className="animate-fade-in-up px-4 bg-white shadow rounded-lg sm:px-6 md:px-8 pt-5 pb-11 md:w-3/4 w-full sm:border-r border-gray-200">
                  <div className="mb-8 flex items-center pt-3 text-gray-600 hover:text-indigo-700 cursor-pointer" onClick={() => setStep(1)}>
                    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.1665 4H12.8332" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
                      <path d="M1.1665 4L4.49984 7.33333" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
                      <path d="M1.1665 4.00002L4.49984 0.666687" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                    <p className="text-base ml-3 font-medium leading-none ">Previous 返回</p>
                  </div>
                  <div className="sm:flex items-center justify-between">
                    <div>
                      <p className="text-base font-bold leading-none text-gray-800">Select Timeslot 選擇時間</p>
                      <p className="text-sm leading-3 text-gray-600 mt-2">as of {format(new Date, 'd MMMM yyyy, hh:mm a')}</p>
                    </div>
                    {weekIntervals && <div className="lg:w-60 w-auto mt-4 sm:mt-0 py-3 px-4 flex items-center text-sm font-medium leading-none text-gray-600 bg-gray-100 hover:bg-gray-200 cursor-pointer rounded">
                      <select value={selectedWeek} onChange={(event) => setSelectedWeek(event.target.value)} className="w-full focus:outline-none bg-transparent ml-1">
                        <option className="text-base text-indigo-800">Select Weeks 選擇星期</option>
                        {weekIntervals.map((weekInterval, index) => {
                          return <option key={index} value={index + 1} className="text-base text-indigo-800">{`${format(startOfWeek(weekInterval), 'dd-MM-yyyy')} to ${format(endOfWeek(weekInterval), 'dd-MM-yyyy')}`}</option>
                        })}
                      </select>
                    </div>}
                  </div>
                  <div className="mt-9">
                    <div className="w-full bg-gray-200 rounded-full h-1 mt-4">
                      <div className="bg-green w-3/4 rounded-full h-1" />
                    </div>
                  </div>
                  {timeslotQuery && <div className="bg-white py-4 w-full md:overflow-auto overflow-scroll flex px-2 md:px-9 items-center justify-between mt-12 border-b border-gray-200 pb-6">
                    {intervals.map((interval, index) => {
                      return <div key={index} className="flex flex-col items-center">
                        <button className="pointer-events-none opacity-50 text-base font-medium leading-3 text-center text-gray-600 focus:outline-none focus:text-indigo-700 hover:text-indigo-700">{weekDaysMap[getISODay(interval) - 1]}</button>
                        {interval && <button className={`pointer-events-none	px-1.5 py-1.5 ${isToday(interval) ? 'bg-indigo-700' : 'bg-transparent text-gray-600'} rounded-full focus:outline-none text-base leading-3 text-center text-white mt-2`}>{getDate(interval)}</button>}
                        {(timeslotQuery.data && !isMaintenanceOn && accountRole === 'PUBLIC' || accountRole !== 'PUBLIC') && <div className="mt-4 w-32 h-96 min-w-min flex flex-col">
                          {getTimeslot(interval, timeslotQuery.data).map((timeSlot, index) => {
                            return (
                              <div key={index} className="flex items-center justify-center">
                                <button disabled={timeSlot.remainSlots <= 0 && (accountRole === 'PUBLIC' || accountRole === 'PCCW_USER')} onClick={() => { animateScroll.scrollToBottom({}); setSelectedTimeSlot(timeSlot); setDate(timeSlot.startAt) }} 
                                className={`block font-bold rounded m-1 px-6 py-4 md:px-10 md:py-6 
                                ${timeSlot.remainSlots <= 0 && (accountRole === 'PUBLIC' || accountRole === 'PCCW_USER') ? 'bg-gray-400 cursor-not-allowed' : 
                                'bg-purple-400 hover:bg-indigo-300 focus:ring-4 focus:ring-red-400 focus:bg-indigo-500'} md:text-base text-base leading-3 text-center mt-4 
                                ${timeSlot.remainSlots < 0 ? 'text-red-600' : 'text-white'}`}>
                                  {`${format(timeSlot.startAt, 'HH:mm')} - ${format(timeSlot.endAt, 'HH:mm')} ${showOccupied ? `(${timeSlot.remainSlots}/${timeSlot.maxTimeSlots})` : ''}`}
                                  </button></div>)
                          })}
                        </div>}
                      </div>
                    })}
                  </div>}
                  {/* <div className="flex lg:hidden w-full items-center justify-between mt-12 border-b border-gray-200 pb-6">
                    {intervals.slice(0, 3).map((interval, index) => {
                      return <div key={index} className="flex flex-col items-center">
                        <button className="pointer-events-none opacity-50 text-base font-medium leading-3 text-center text-gray-600 focus:outline-none focus:text-indigo-700 hover:text-indigo-700">{weekDaysMap[getISODay(interval)]}</button>
                        <button className={`pointer-events-none	px-1.5 py-1.5 ${isToday(interval) ? 'bg-indigo-700' : 'bg-transparent text-gray-600'} rounded-full focus:outline-none text-base leading-3 text-center text-white mt-2`}>{getDate(interval)}</button>
                        <div className="mt-4 h-48 min-w-min">
                          {getSchedule(interval, schedules).map((timeSlot, index) => {
                            return (
                              <button key={index} onClick={() => setSelectedTimeSlot(timeSlot)} className="block font-bold rounded m-1 px-4 py-4 md:px-10 md:py-6 bg-purple-400	hover:bg-indigo-300 focus:ring-4 focus:ring-red-400 focus:bg-indigo-500 md:text-base text-sm leading-3 text-center text-white mt-4">{format(timeSlot.startAt, 'HH:mm')}</button>)
                          })}
                        </div>
                      </div>
                    })}
                  </div>
                  <div className="flex lg:hidden w-full items-center justify-between mt-12 border-b border-gray-200 pb-6">
                    {intervals.slice(-4).map((interval, index) => {
                      return <div key={index} className="flex flex-col items-center">
                        <button className="pointer-events-none opacity-50 text-base font-medium leading-3 text-center text-gray-600 focus:outline-none focus:text-indigo-700 hover:text-indigo-700">{weekDaysMap[getISODay(interval)]}</button>
                        <button className={`pointer-events-none	px-1.5 py-1.5 ${isToday(interval) ? 'bg-indigo-700' : 'bg-transparent text-gray-600'} rounded-full focus:outline-none text-base leading-3 text-center text-white mt-2`}>{getDate(interval)}</button>
                        <div className="mt-4 h-48 min-w-min">
                          {getSchedule(interval, schedules).map((timeSlot, index) => {
                            return (
                              <button key={index} onClick={() => setSelectedTimeSlot(timeSlot)} className="block font-bold rounded m-1 px-4 py-4 md:px-10 md:py-6 bg-purple-400	hover:bg-indigo-300 focus:ring-4 focus:ring-red-400 focus:bg-indigo-500 md:text-base text-sm leading-3 text-center text-white mt-4">{format(timeSlot.startAt, 'HH:mm')}</button>)
                          })}
                        </div>
                      </div>
                    })}
                  </div> */}
                  <div className="mt-8">
                    {selectedTimeSlot && <div className="animate-fade-in-down sm:flex justify-between items-end">
                      <div>
                        <p className="text-base font-medium leading-none text-gray-700 whitespace-nowrap">You have selected 你已選擇:</p>
                        <div className="flex items-center space-x-5 mt-4 mb-4">
                          <div className="flex items-center">
                            <p className="underline text-xl text-green-600">{format(selectedTimeSlot.startAt, 'd MMMM yyyy, hh:mm a')} - {format(selectedTimeSlot.endAt, 'hh:mm a')}</p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-10 rounded-md shadow">
                        <a
                          className="cursor-pointer w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-jebsen-default hover:bg-indigo-700 md:py-4 md:text-lg md:px-8"
                          onClick={() => setStep(3, setConfirmedTimeSlot, selectedTimeSlot)}
                        >
                          Select 選擇
                        </a>
                      </div>
                    </div>}
                  </div>
                </div>
              </>}


              {currentStep === 3 && <>

                <div className="animate-fade-in-up w-full bg-gray-200 dark:bg-gray-900">

                  <div className="container mx-auto lg:px-6 flex items-start justify-center">

                    <div className="w-full">

                      {/* Card is full width. Use in 12 col grid for best view. */}
                      {/* Card code block start */}
                      <div className="flex flex-col lg:flex-row mx-auto bg-white dark:bg-gray-800 shadow rounded">

                        <div className="w-full lg:w-1/2 px-12 flex flex-col items-center py-10">
                          <div>
                            <div className="mb-2 flex items-center pt-3 text-gray-600 hover:text-indigo-700 cursor-pointer" onClick={() => setStep(2)}>
                              <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.1665 4H12.8332" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M1.1665 4L4.49984 7.33333" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M1.1665 4.00002L4.49984 0.666687" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                              <p className="text-base ml-3 font-medium leading-none ">Previous 返回</p>
                            </div>
                            <h1 tabIndex={0} role="heading" aria-label="profile information" className="focus:outline-none text-3xl font-bold text-gray-800 mt-6">
                              Confirm Details 確認資料
                            </h1>
                            <h2 role="heading" aria-label="enter Personal data" className="text-xl font-semibold leading-7 text-gray-800 mt-10">
                              Service 服務
                            </h2>
                            <p className="text-base font-light leading-none text-gray-600 mt-0.5">{service}</p>
                            <h2 role="heading" aria-label="enter Personal data" className="text-xl font-semibold leading-7 text-gray-800 mt-10">
                              Time 時間
                            </h2>
                            <div className="mt-4 flex items-center text-gray-600 dark:text-gray-100">
                              <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                                <rect x="3.33337" y="4.16699" width="13.3333" height="13.3333" rx={2} stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M13.3333 2.5V5.83333" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.66667 2.5V5.83333" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M3.33337 9.16667H16.6667" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.16663 12.4997H9.99996" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10 12.5V15" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <p className="text-base leading-none text-gray-600 dark:text-gray-100 ml-2">{format(confirmedTimeSlot.startAt, 'd MMMM yyyy')}</p>
                            </div>
                            <div className="mt-4 flex items-center text-gray-600 dark:text-gray-100">
                              <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                                <circle cx={10} cy={10} r="7.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10 5.83301V9.99967L12.5 12.4997" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                              <p className="text-base leading-none text-gray-600 dark:text-gray-100 ml-2">{format(confirmedTimeSlot.startAt, 'eeee, hh:mm a')} - {format(confirmedTimeSlot.endAt, 'hh:mm a')}</p>
                            </div>


                            <div className="mt-8 md:flex items-center">
                              <div className="flex flex-col">
                                <label className="mb-3 text-base leading-none text-gray-600">Name 姓名</label>
                                <input value={name} onInput={e => setName(e.target.value)} type="name" placeholder="Enter name" tabIndex={0} aria-label="Enter name" className={`${isError('name', name, true) ? 'border-red-500' : 'border-gray-200'} w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded`} />
                                {isError('name', name, true) && <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                  {DEFAULT_ERROR_MSG}
                                </span>}
                              </div>
                            </div>
                            <div className="mt-12 md:flex items-center">
                              <div className="flex flex-col">
                                <label className="mb-3 text-base leading-none text-gray-600">Email Address 電郵地址</label>
                                <input value={email} onInput={e => setEmail(e.target.value)} type="email" placeholder="Enter email Address" tabIndex={0} aria-label="Enter email address" className={`${isError('email', email, true) ? 'border-red-500' : 'border-gray-200'} w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded`} />
                                {isError('email', email, true) && <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                  {DEFAULT_EMAIL_ERROR_MSG}
                                </span>}
                              </div>
                              <div className="flex flex-col md:ml-12 md:mt-0 mt-8">
                                <label className="mb-3 text-base leading-none text-gray-600">Mobile 手提號碼</label>
                                <div className="relative">
                                  <div className="absolute text-gray-600 flex items-center pl-3 border-r dark:border-gray-700 h-full pr-1">
                                    <select value={countryCode} onChange={e => setCountryCode(e.target.value)} className="uppercase text-sm leading-tight tracking-normal focus:outline-none h-8  pr-2 z-20 relative bg-transparent">
                                      <option value="852">+852</option>
                                      <option value="853">+853</option>
                                      <option value="86">+86</option>
                                    </select>
                                  </div>
                                  <input value={phone} onInput={e => { setPhone(e.target.value); setIsExceededReserveLimit(false) }} id="phone" className={`${isError('phone', phone, true) ? 'border-red-500' : 'border-gray-200'} bg-gray-100 text-gray-600  font-normal w-64 h-11 flex items-center pl-24 text-sm rounded border`} placeholder="Enter mobile number" />

                                </div>
                                {isError('phone', phone, true) && <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                  {isExceededReserveLimit ? DEFAULT_EXCEED_LIMIT_ERROR : DEFAULT_PHONE_ERROR_MSG}
                                </span>}
                                {/* <input type="number" placeholder="Enter phone number" tabIndex={0} aria-label="Enter phone number" className="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200" /> */}
                              </div>
                            </div>
                          </div>
                          <style dangerouslySetInnerHTML={{ __html: "\n          .checkbox:checked + .check-icon {\n              display: flex;\n          }\n      " }} />
                        </div>

                        <div className="w-full lg:w-1/2 px-12 border-t border-b lg:border-t-0 lg:border-b-0 lg:border-l lg:border-r border-gray-300 flex flex-col items-left py-10">
                          <div>
                            <div className="mt-12 md:flex items-center">
                              <div className="flex flex-col">
                                <label className="mb-3 text-base leading-none text-gray-600">Please select the product brand 請選擇產品品牌</label>
                                <div className={`${isError('brand', brand, true) ? 'border-red-500' : 'border-gray-200'} border lg:w-96 w-full mt-4 sm:mt-0 py-3 px-4 flex items-center text-sm font-medium leading-none text-gray-600 bg-gray-100 hover:bg-gray-200 cursor-pointer rounded`}>
                                  {brandQuery.data && <select value={brand} onChange={e => {
                                    setBrand(e.target.value)
                                  }} className="w-full focus:outline-none bg-transparent ml-1">
                                    <option className="text-base text-indigo-800">Please select 請選擇</option>
                                    {orderBy(brandQuery.data, ['sorting'], ['asc']).filter(brand => {
                                      // if (accountRole === 'DYSON_USER') {
                                      //   return brand.id === HARD_CODE_DYSON_BRAND_ID
                                      // }
                                      return brand;
                                    }).map((brand, index) => (
                                      <option key={index} value={brand.id} className="text-base text-indigo-800">{brand.name}</option>
                                    ))}
                                    {/* <option className="text-base text-indigo-800">Airfree</option>
                                    <option className="text-base text-indigo-800">Beurer</option>
                                    <option className="text-base text-indigo-800">Casio</option>
                                    <option className="text-base text-indigo-800">Dyson</option>
                                    <option className="text-base text-indigo-800">Stadler Form</option>
                                    <option className="text-base text-indigo-800">Ya-man</option>
                                    <option className="text-base text-indigo-800">Others</option> */}
                                  </select>}
                                </div>
                                {isError('brand', brand, true) && <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                  {DEFAULT_ERROR_MSG}
                                </span>}
                              </div>
                            </div>
                            {serviceCode === 'REPAIR' && <div className="mt-12 md:flex items-center">
                              <div className="flex flex-col">
                                <label className="mb-3 text-base leading-none text-gray-600">Please input the symptom 請輸入所遇到的問題</label>
                                <input value={symptom} onInput={e => setSymptom(e.target.value)} type="text" placeholder="Enter the symptom" tabIndex={0} aria-label="Enter the symptom" className={`lg:w-96 w-full bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded ${isError('symptom', symptom, true) ? 'border-red-500' : 'border-gray-200'}`} />
                                {isError('symptom', symptom, true) && <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                  {DEFAULT_ERROR_MSG}
                                </span>}
                              </div>
                            </div>}
                            {serviceCode === 'REPAIR' && <div className="mt-12 md:flex items-center">
                              <div className="flex flex-col">
                                <label className="mb-3 text-base leading-none text-gray-600">Please input the date of purchase 請輸入購買日期</label>
                                <Datepicker placeholder="Enter the date of purchase" date={purchasedAt} onChange={setPurchasedAt}></Datepicker>
                                {/* <input type="text" placeholder="Enter the date of purchase" tabIndex={0} aria-label="Enter the date of purchase" className="lg:w-96 w-full bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200" /> */}
                              </div>
                            </div>}
                            {serviceCode === 'COLLECTION' && <div className="mt-12 md:flex items-center">
                              <div className="flex flex-col">
                                <label className="mb-3 text-base leading-none text-gray-600">Please input your service request number 請輸入您的維修單編號</label>
                                <input value={serviceNo} onInput={e => setServiceNo(e.target.value)} type="text" placeholder="Enter the service request number" tabIndex={0} aria-label="Enter the service request number" className={`${isError('serviceNo', serviceNo, true) ? 'border-red-500' : 'border-gray-200'} lg:w-96 w-full bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded`} />
                                {isError('serviceNo', serviceNo, true) && <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                  {DEFAULT_ERROR_MSG}
                                </span>}
                              </div>
                            </div>}
                            {serviceCode === 'PURCHASE' && <div className="mt-12 md:flex items-center">
                              <div className="flex flex-col">
                                <label className="mb-3 text-base leading-none text-gray-600">Please input your purchasing products 請輸入您的購買產品</label>
                                <input value={productName} onInput={e => setProductName(e.target.value)} type="text" placeholder="Enter your purchasing products" tabIndex={0} aria-label="Enter your purchasing products" className={`lg:w-96 w-full bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded ${isError('productName', productName, true) ? 'border-red-500' : 'border-gray-200'}`} />
                                {isError('productName', productName, true) && <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                  {DEFAULT_ERROR_MSG}
                                </span>}
                              </div>
                            </div>}
                            {!noBanner && <div className="mt-12">
                              <div className="py-4 flex items-center">
                                <div className="bg-white dark:bg-gray-800 border rounded-sm border-gray-400 dark:border-gray-700 w-4 h-4 flex flex-shrink-0 justify-center items-center relative">
                                  <input value={isAgreedTnc} onChange={() => setIsAgreedTnc(!isAgreedTnc)} defaultChecked type="checkbox" tabIndex={0} aria-label="I agree with the terms of service" className="checkbox opacity-0 absolute cursor-pointer w-full h-full" />
                                  <div className="check-icon hidden bg-blue-500 text-white rounded-sm">
                                    <svg className="icon icon-tabler icon-tabler-check" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" />
                                      <path d="M5 12l5 5l10 -10" />
                                    </svg>
                                  </div>
                                </div>

                                <p className="text-sm font-bold leading-none ml-2">
                                  I agree with the <span><a className="text-indigo-700 cursor-pointer underline" target="_blank" href={`${process.env.REACT_APP_STRAPI_DOMAIN}${termsAndConditionsFile.url}`}>Terms & Conditions</a></span>
                                  <p className="text-sm font-bold leading-none mt-2">
                                    我同意 <span><a className="text-indigo-700 cursor-pointer underline" target="_blank" href={`${process.env.REACT_APP_STRAPI_DOMAIN}${termsAndConditionsFile.url}`}>條款細則</a></span>
                                  </p>
                                </p>
                              </div>
                              {isError('isAgreedTnc', isAgreedTnc, true) && <span class="flex items-center font-medium tracking-wide text-red-500 text-xs">
                                Must agree T&C to proceed 必須同意條款細則
                              </span>}
                            </div>}
                            <div>
                              <div className="py-4 flex items-center">
                                <div className="bg-white dark:bg-gray-800 border rounded-sm border-gray-400 dark:border-gray-700 w-4 h-4 flex flex-shrink-0 justify-center items-center relative">
                                  <input value={isSubscribed} onChange={() => setIsSubscribed(!isSubscribed)} type="checkbox" tabIndex={0} aria-label="I agree with the terms of service" defaultChecked className="checkbox opacity-0 absolute cursor-pointer w-full h-full" />
                                  <div className="check-icon hidden bg-blue-500 text-white rounded-sm">
                                    <svg className="icon icon-tabler icon-tabler-check" xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" />
                                      <path d="M5 12l5 5l10 -10" />
                                    </svg>
                                  </div>
                                </div>
                                <p className="text-sm font-bold leading-none ml-2">
                                  Be one of the first to receive messages about our promotions and other cool stuff.
                                  <p className="text-sm font-bold leading-none mt-2">
                                    同意透過短訊接收宣傳及其他資訊。
                                  </p>
                                </p>
                              </div>
                            </div>
                            <div className="w-full mt-2 lg:mt-14 mt-10 bg-white dark:bg-gray-800 lg:flex lg:justify-end">

                              <div className="rounded-md shadow">
                                <a
                                  className={`${submittedAlert ? 'cursor-not-allowed hover:bg-gray-300 bg-gray-500' : 'hover:bg-indigo-700 bg-jebsen-default cursor-pointer'} w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white md:py-4 md:text-lg md:px-8`}
                                  onClick={() => attemptSubmission()}
                                >
                                  {submittedAlert ? 'Loading 加載中' : 'Confirm 確認'}
                                </a>
                              </div>
                            </div>
                          </div>
                          <style dangerouslySetInnerHTML={{ __html: "\n          .checkbox:checked + .check-icon {\n              display: flex;\n          }\n      " }} />
                        </div>


                      </div>
                      {/* Card code block end */}
                    </div>
                  </div>
                </div>
              </>}
            </Fragment>}

            {currentStep === 4 &&
              <div className="flex items-center justify-center py-8">

                <div className="animate-fade-in-down grid place-items-center relative z-10 text-gray-800">
                  <div className="grid place-items-center w-full h-full px-4 lg:px-0">
                    <h1 className="text-center text-3xl sm:text-5xl p-2 font-bold text-gray-800">Booking Confirmed<br /><p className="mt-2">已確認預約</p><hr className="text-red-200" /></h1>
                    <p className="text-xl text-center text-gray-800 pt-6 pb-10">
                      <p className="my-4 text-base md:text-xl font-medium leading-none text-jebsen-default dark:text-gray-100"><p className="font-bold my-1">Booking ID 預約號碼</p><p className="mt-2 text-red-500">{bookingId}</p></p>
                      <p className="my-4 text-base md:text-xl font-medium leading-none text-jebsen-default dark:text-gray-100"><p className="font-bold my-1">Time 時間</p><p className="mt-2 text-red-500">{format(new Date(confirmedTimeSlot.startAt), 'd MMMM yyyy hh:mm a')} - {format(new Date(confirmedTimeSlot.endAt), 'hh:mm a')}</p></p>
                      <p className="my-4 text-base md:text-xl font-medium leading-none text-jebsen-default dark:text-gray-100"><p className="font-bold my-1">Location 地點</p><p className="mt-2 text-red-500">Jebsen Consume Products Service Centre</p></p></p>
                    <div className="pb-12 flex flex-col lg:flex-row justify-center gap-4 w-full lg:w-auto">
                      <button onClick={() => setShowMyBooking(true)} className="bg-white border rounded-md border-jebsen-default text-jebsen-default px-8 py-4 w-full lg:w-auto hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-opacity-50">My Bookings 我的預約</button>
                      <button onClick={() => showBookingForm()} className="border rounded-md bg-jebsen-default text-white px-8 py-4 w-full lg:w-auto hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-opacity-50">New Booking 再次預約</button>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div >
  );
}

export default App;