import React, { Fragment, useState, useEffect } from 'react'
import {
    useQuery,
    useMutation,
} from 'react-query'
import axios from 'axios'

export default function Config({ setDrawerShow, setProduct, showSuccessDialog }) {
    const FIXED_CONFIG_ID = 1;
    const DEFAULT_SLOTS = 10;
    const DEFAULT_DYSON_SLOTS = 2;

    const [timeslotReserve, setTimeslotReserve] = useState(null);
    const [aheadOfWeek, setAheadOfWeek] = useState(null);
    const [isMaintenanceOn, setIsMaintenanceOn] = useState(false);
    const [uploadFile, setUploadFile] = useState(null);
    const [termsAndConditionsFile, setTermsAndConditionsFile] = useState(null);
    const [defaultSlots, setDefaultSlots] = useState(0);
    const [defaultDysonSlots, setDefaultDysonSlots] = useState(0);

    useEffect(() => {
        setDrawerShow(false);
        setProduct(false);
    });

    useQuery('configurations', () =>
        fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/configurations`).then((res) =>
            res.json()
        ).then(data => {
            setDefaultSlots(data[0].defaultSlots);
            setDefaultDysonSlots(data[0].defaultDysonSlots);
            setTimeslotReserve(data[0].timeslotReserve);
            setAheadOfWeek(data[0].aheadOfWeek);
            setIsMaintenanceOn(data[0].isMaintenanceOn);
            setTermsAndConditionsFile(data[0].termsAndConditionsFile);

            return data;
        }),
        { refetchOnWindowFocus: false })

    const mutation = useMutation(data => {
        return axios.put(`${process.env.REACT_APP_STRAPI_DOMAIN}/configurations/${FIXED_CONFIG_ID}`, data)
    })

    const handleFileUpload = (event) => {
        const formData = new FormData();
        formData.append('files', event.target.files[0]);

        fetch(
            `${process.env.REACT_APP_STRAPI_DOMAIN}/upload`,
            {
                method: 'POST',
                body: formData,
            }
        )
            .then((response) => response.json())
            .then((result) => {
                if (result) {
                    setUploadFile(result[0].id)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };


    const saveConfig = async () => {
        try {
            const result = await mutation.mutateAsync({
                defaultSlots: defaultSlots || DEFAULT_SLOTS,
                defaultDysonSlots: defaultDysonSlots || DEFAULT_DYSON_SLOTS,
                timeslotReserve,
                aheadOfWeek,
                isMaintenanceOn,
                termsAndConditionsFile: uploadFile ? uploadFile : termsAndConditionsFile.id
            })
            if (result) {
                const termsAndConditionsFile = result.data.termsAndConditionsFile;
                setTermsAndConditionsFile(termsAndConditionsFile)
                showSuccessDialog('Data Successfully Saved');
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Fragment>
            <div className="animate-fade-in-down">
                <div className="xl:w-full border-b border-gray-300 py-5">
                    <div className="flex items-center w-11/12 mx-auto">
                        <div className="container mx-auto">
                            <div className="mx-auto xl:w-full">
                                <p className="text-2xl text-gray-800 font-bold">Bookings</p>
                                <p className="text-sm text-gray-500 pt-1">Configurations for Jebsen Online Booking System</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="container mx-auto bg-white dark:bg-gray-800 py-6 w-11/12">
                        <div className="flex items-center mb-8 mt-8 text-gray-800 dark:text-gray-100">
                            <p className="text-sm font-bold text-gray-800 dark:text-gray-100">Booking Application Settings</p>
                        </div>
                        <div className="flex justify-between items-center mb-8">
                            <div className="w-9/12">
                                <p className="text-sm text-gray-800 dark:text-gray-100 pb-1">No. of timeslots for reservation</p>
                                <p className="text-sm text-gray-500 dark:text-gray-400">Visitor is allowed to reserve X timeslots per week</p>
                            </div>
                            <div className="flex items-center mt-4 md:mt-0 md:ml-3 lg:ml-0 mr-1">
                                <div className="w-full py-2 px-3 bg-white lg:ml-3 border rounded-md border-gray-200">
                                    <select value={timeslotReserve} onChange={e => setTimeslotReserve(e.target.value)} className="uppercase text-sm leading-tight tracking-normal focus:outline-none h-8  pr-2 z-20 relative bg-transparent">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between items-center mb-8">
                            <div className="w-9/12">
                                <p className="text-sm text-gray-800 dark:text-gray-100 pb-1">Reserve ahead of time</p>
                                <p className="text-sm text-gray-500 dark:text-gray-400">Visitor is allowed to reserve timeslots X week(s) ahead</p>
                            </div>
                            <div className="flex items-center mt-4 md:mt-0 md:ml-3 lg:ml-0 mr-1">
                                <div className="w-full py-2 px-3 bg-white lg:ml-3 border rounded-md border-gray-200">
                                    <select value={`${aheadOfWeek}`} onChange={e => setAheadOfWeek(e.target.value)} className="uppercase text-sm leading-tight tracking-normal focus:outline-none h-8  pr-2 z-20 relative bg-transparent">
                                        <option value="1">1 week</option>
                                        <option value="2">2 weeks</option>
                                        <option value="3">3 weeks</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between items-center mb-8">
                            <div className="w-9/12">
                                <p className="text-sm text-gray-800 dark:text-gray-100 pb-1">Number of slots</p>
                                <p className="text-sm text-gray-500 dark:text-gray-400">Default slots number for when setting timeslot</p>
                            </div>
                            <div className="flex items-center mt-4 md:mt-0 md:ml-3 lg:ml-0 mr-1">
                                <div className="w-full py-2 px-3 bg-white lg:ml-3 border rounded-md border-gray-200">
                                    <input value={defaultSlots} onInput={e => setDefaultSlots(e.target.value)} type="number" placeholder="Enter slots" tabIndex={0} aria-label="Enter default slots" className='border-gray-200 w-24 text-sm font-medium leading-none text-gray-800 p-3' />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between items-center mb-8">
                            <div className="w-9/12">
                                <p className="text-sm text-gray-800 dark:text-gray-100 pb-1">Number of slots (Additional)</p>
                                <p className="text-sm text-gray-500 dark:text-gray-400">Default slots number (Additional) for when setting timeslot</p>
                            </div>
                            <div className="flex items-center mt-4 md:mt-0 md:ml-3 lg:ml-0 mr-1">
                                <div className="w-full py-2 px-3 bg-white lg:ml-3 border rounded-md border-gray-200">
                                    <input value={defaultDysonSlots} onInput={e => setDefaultDysonSlots(e.target.value)} type="number" placeholder="Enter slots" tabIndex={0} aria-label="Enter default slots (Additional)" className='border-gray-200 w-24 text-sm font-medium leading-none text-gray-800 p-3' />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between items-center mb-8">
                            <div className="w-9/12">
                                <p className="text-sm text-gray-800 dark:text-gray-100 pb-1">Maintenance</p>
                                <p className="text-sm text-gray-500 dark:text-gray-400">Temporary disable all booking timeslots (Turn-on = disable)</p>
                            </div>
                            <div className="cursor-pointer rounded-full relative shadow-sm">
                                <input type="checkbox" checked={isMaintenanceOn} onChange={e => setIsMaintenanceOn(!isMaintenanceOn)} className="focus:outline-none checkbox w-4 h-4 rounded-full bg-white absolute m-1 shadow-sm appearance-none cursor-pointer" />
                                <label htmlFor="toggle4" className="toggle-label dark:bg-gray-700 block w-12 h-6 overflow-hidden rounded-full bg-gray-300 cursor-pointer" />
                            </div>
                        </div>

                        <div className="flex items-center mb-8 mt-12 text-gray-800 dark:text-gray-100">
                            <p className="text-sm font-bold text-gray-800 dark:text-gray-100">Terms and Conditions</p>
                        </div>
                        <div className="flex justify-between items-center mb-8">
                            <div className="flex flex-col w-full">
                                <input type="file" name="file" onChange={handleFileUpload} /><br />
                                {termsAndConditionsFile && <span>Uploaded File: <a className="text-indigo-700 cursor-pointer underline" target="_blank" href={`${process.env.REACT_APP_STRAPI_DOMAIN}${termsAndConditionsFile.url}`}>{termsAndConditionsFile.name}</a> ({termsAndConditionsFile.size} KB)</span>}
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 w-full py-4 sm:px-12 px-4 dark:bg-gray-700 flex justify-end rounded-bl rounded-br">
                        <button onClick={() => saveConfig()} className="h-10 bg-indigo-700 focus:outline-none transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-8 py-2 text-sm">Save Configurations</button>
                    </div>
                </div>
                <style>
                    {` .checkbox:checked {
                        /* Apply class right-0*/
                        right: 0;
                    }
                    .checkbox:checked + .toggle-label {
                        /* Apply class bg-indigo-700 */
                        background-color: #4c51bf;
                    }`}
                </style>
            </div>
        </Fragment>)
}