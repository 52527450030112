import { Fragment, useState, useRef, useEffect } from 'react'
import TimeField from 'react-simple-timefield';
import { Datepicker } from "./Datepicker";
import {
    useQuery,
    useMutation,
} from 'react-query'
import { eachMinuteOfInterval, setMinutes, setHours, format, parse, addHours } from 'date-fns';
import { orderBy, uniqBy, findIndex, isString, omit, get } from 'lodash'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export default function Config({ setDrawerShow, setProduct, showSuccessDialog }) {
    const DEFAULT_DELAY = 2000;
    const DEFAULTS_INITITAL_SLOTS = 10;
    const DEFAULT_DYSON_SLOTS = 2;
    const [batchDate, setBatchDate] = useState(new Date())
    const [defaultSlots, setDefaultSlots] = useState(DEFAULTS_INITITAL_SLOTS)
    const [defaultDysonSlots, setDefaultDysonSlots] = useState(DEFAULT_DYSON_SLOTS)
    const [timeslots, setTimeslots] = useState([])
    const [weekDaysMap, setWeekDaysMap] = useState([
        'MON',
        'TUE',
        'WED',
        'THU',
        'FRI',
        'SAT',
        'SUN',
        'SPECIFIC'
    ])

    const setSingleItem = (value, field, timeslot) => {
        const index = findIndex(timeslots, { id: timeslot.id });
        const newTimeslots = timeslots;

        if (field === 'startAt' || field === 'endAt') {
            value = `${value}:00.000`
        }

        if (field === 'slots' && !value) {
            value = 0;
        }

        if (field === 'date') {
            // tricky date to add 8 more hours avoid the error of capting only the date (wrong capture on date 16:00:00 = next date)
            value = addHours(new Date(value), 8);
            value = format(value, "yyyy-MM-dd");
        }

        newTimeslots.splice(index, 1, { ...timeslot, [field]: value });

        // setTimeslots(orderBy(newTimeslots, ['date', 'startAt'], ['asc', 'asc']));
        setTimeslots(newTimeslots);

        setWeekDaysMap([
            'MON',
            'TUE',
            'WED',
            'THU',
            'FRI',
            'SAT',
            'SUN',
            'SPECIFIC'
        ])

    }

    const addSingleTimeslot = (type) => {
        const t = orderBy([
            ...timeslots,
            {
                startAt: '00:00:00.000',
                endAt: '00:00:00.000',
                slots: defaultSlots,
                dysonSlots: defaultDysonSlots,
                date: type === 'SPECIFIC' ? format(batchDate, "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd"),
                type,
                isNew: true,
                id: uuidv4()
            }
        ], [function(timeslot) { return timeslot.type !== 'SPECIFIC' ? timeslot.type: timeslot.date; }, 'startAt'], ['asc', 'asc']);
        setTimeslots(t)
    }

    const addMultipleTimeslots = (start, end, type) => {
        const intervals = eachMinuteOfInterval({
            start,
            end,
        }, { step: 30 }); // TODO step config

        const intervalTimeslots = [];

        for (let index = 0; index < intervals.length; index++) {
            if (intervals[index + 1]) {
                intervalTimeslots.push({
                    startAt: format(intervals[index], 'HH:mm:ss.SSS'),
                    endAt: format(intervals[index + 1], 'HH:mm:ss.SSS'),
                    slots: defaultSlots,
                    dysonSlots: defaultDysonSlots,
                    date: format(new Date(batchDate), "yyyy-MM-dd"),
                    type,
                    isNew: true,
                    id: uuidv4()
                })
            }
        }

        const t = uniqBy(orderBy([
            ...timeslots,
            ...intervalTimeslots
        ], [function(timeslot) { return timeslot.type !== 'SPECIFIC' ? timeslot.type: timeslot.date; }, 'startAt'], ['asc', 'asc']), object => {
            return `${get(object, 'date', 'NA')} - ${object.startAt} - ${object.endAt} - ${object.type}`
        })

        setTimeslots(t)
    }

    const addNormalTimeslots = (type) => {
        addMultipleTimeslots(setMinutes(setHours(new Date(), 9), 30), setMinutes(setHours(new Date(), 18), 0), type);
    }

    const addWeekendTimeslots = (type) => {
        addMultipleTimeslots(setMinutes(setHours(new Date(), 9), 30), setMinutes(setHours(new Date(), 16), 0), type);
    }

    const addHalfDayTimeslots = (type) => {
        addMultipleTimeslots(setMinutes(setHours(new Date(), 9), 30), setMinutes(setHours(new Date(), 12), 0), type);
    }

    const { refetch } = useQuery('timeslots', () =>
        fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/timeslots`)
            .then((res) =>
                res.json()
            ).then(data => {
                setTimeslots(orderBy(data, [function(timeslot) { return timeslot.type !== 'SPECIFIC' ? timeslot.type: timeslot.date; }, 'startAt'], ['asc', 'asc']));
                return data;
            }),
        { refetchOnWindowFocus: false })

    useQuery('configurations', () =>
        fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/configurations`).then((res) =>
            res.json()
        ).then(data => {
            setDefaultSlots(data[0].defaultSlots);
            setDefaultDysonSlots(data[0].defaultDysonSlots);
            return data;
        }),
        { refetchOnWindowFocus: false })

    useEffect(() => {
        setDrawerShow(false);
        setProduct(false);
    }, []);

    const upsertMutation = useMutation(timeslots => {
        let timeslotRes;
        let res = [];
        for (let timeslot of timeslots) {
            if (isString(timeslot.date)) {
                timeslot.date = parse(timeslot.date, 'yyyy-MM-dd', new Date())
                // tricky date to add 8 more hours avoid the error of capting only the date (wrong capture on date 16:00:00 = next date)
                timeslot.date = addHours(new Date(timeslot.date), 8);
            }

            if (timeslot.isNew) {
                // tricky date to add 8 more hours avoid the error of capting only the date (wrong capture on date 16:00:00 = next date)
                timeslot.date = addHours(new Date(timeslot.date), 8);

                timeslotRes = axios.post(`${process.env.REACT_APP_STRAPI_DOMAIN}/timeslots`, omit(timeslot, ['isNew', 'id']))
            } else {
                // tricky date to add 8 more hours avoid the error of capting only the date (wrong capture on date 16:00:00 = next date)
                timeslot.date = addHours(new Date(timeslot.date), 8);

                timeslotRes = axios.put(`${process.env.REACT_APP_STRAPI_DOMAIN}/timeslots/${timeslot.id}`, timeslot)
            }

            res = [...res, timeslotRes];
        }

        return res
    })

    const deleteMutation = useMutation(timeslot => {
        return axios.delete(`${process.env.REACT_APP_STRAPI_DOMAIN}/timeslots/${timeslot.id}`);
    })

    const upsertTimeslots = async () => {
        try {
            const result = await upsertMutation.mutateAsync(timeslots)
            Promise.all(result).then((values) => {
                refetch();
                setTimeslots(orderBy(timeslots, [function(timeslot) { return timeslot.type !== 'SPECIFIC' ? timeslot.type: timeslot.date; }, 'startAt'], ['asc', 'asc']));
                showSuccessDialog('Timeslots Successfully Created');
            }, DEFAULT_DELAY);
        } catch (error) {
            console.error(error)
        }
    }

    const deleteTimeslot = async (timeslot) => {
        try {
            if (timeslot.isNew) {
                const allTimeslots = timeslots.filter(_timeslot => {
                    return _timeslot.id !== timeslot.id
                })
                setTimeslots(allTimeslots)
                return;
            }
            const result = await deleteMutation.mutateAsync(timeslot)
            if (result) {
                setTimeout(() => {
                    refetch();
                    showSuccessDialog('Timeslot Successfully Deleted');
                }, DEFAULT_DELAY);
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Fragment>
            <div className="animate-fade-in-down">
                <div className="xl:w-full border-b border-gray-300 py-5">
                    <div className="flex items-center w-11/12 mx-auto">
                        <div className="container mx-auto">
                            <div className="mx-auto xl:w-full">
                                <p className="text-2xl text-gray-800 font-bold">Timeslots</p>
                                <p className="text-sm text-gray-500 pt-1">Configurations for Jebsen Online Booking System</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="container mx-auto bg-white dark:bg-gray-800 py-6 w-11/12">
                        {weekDaysMap.map(weekday => {
                            return (<Fragment><div className="flex items-center mt-8 text-gray-800 dark:text-gray-100">
                                <p className="text-xl font-bold text-gray-800 dark:text-gray-100">{weekday}</p>
                                <div className="w-full flex justify-end">
                                    {weekday === 'SPECIFIC' && <Datepicker date={batchDate} onChange={setBatchDate}></Datepicker>}
                                    {weekday === 'SPECIFIC' && <button onClick={() => addWeekendTimeslots(weekday)} className="h-10 inline-flex sm:ml-3 items-start justify-start px-6 py-3 bg-jebsen-default hover:bg-indigo-600 focus:outline-none rounded">
                                        <p className="text-base font-medium leading-none text-white">+ Weekend 假日</p>
                                    </button>}
                                    {weekday === 'SPECIFIC' && <button onClick={() => addHalfDayTimeslots(weekday)} className="h-10 inline-flex sm:ml-3 items-start justify-start px-6 py-3 bg-jebsen-default hover:bg-indigo-600 focus:outline-none rounded">
                                        <p className="text-base font-medium leading-none text-white">+ Half 半日 </p>
                                    </button>}
                                    {weekday === 'SPECIFIC' && <button onClick={() => addNormalTimeslots(weekday)} className="h-10 inline-flex sm:ml-3 items-start justify-start px-6 py-3 bg-jebsen-default hover:bg-indigo-600 focus:outline-none rounded">
                                        <p className="text-base font-medium leading-none text-white">+ Normal 平日</p>
                                    </button>}
                                    <button onClick={() => addSingleTimeslot(weekday)} className="h-10 inline-flex sm:ml-3 items-start justify-start px-6 py-3 bg-jebsen-default hover:bg-indigo-600 focus:outline-none rounded">
                                        <p className="text-base font-medium leading-none text-white">+ Single</p>
                                    </button>
                                </div>

                            </div>
                                <hr className="mt-4" />

                                <div className="bg-white px-4 md:px-10 pb-5">
                                    <div className="overflow-x-auto">
                                        <table className="w-full whitespace-nowrap">
                                            <tbody>
                                                {!!(timeslots.filter(timeslot => timeslot.type === weekday)).length && <tr className="text-sm leading-none text-gray-600 h-16">
                                                    <td className="w-2/5">
                                                        <div className="flex items-center">
                                                            <div className="p-2">
                                                                <div className="md:flex items-center">
                                                                    <div className="flex flex-col">
                                                                        <label className="mb-3 text-base leading-none text-gray-600">Date</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div className="flex items-center">
                                                            <div className="p-2">
                                                                <div className="md:flex items-center">
                                                                    <div className="flex flex-col">
                                                                        <label className="mb-3 text-base leading-none text-gray-600">Start At</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div className="flex items-center">
                                                            <div className="p-2">
                                                                <div className="md:flex items-center">
                                                                    <div className="flex flex-col">
                                                                        <label className="mb-3 text-base leading-none text-gray-600">End At</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div className="flex items-center">
                                                            <div className="p-2">
                                                                <div className="md:flex items-center">
                                                                    <div className="flex flex-col">
                                                                        <label className="mb-3 text-base leading-none text-gray-600">Jebsen Quota</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div className="flex items-center">
                                                            <div className="p-2">
                                                                <div className="md:flex items-center">
                                                                    <div className="flex flex-col">
                                                                        <label className="mb-3 text-base leading-none text-gray-600">Additional Quota</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div className="flex items-center">
                                                            <div className="p-2">
                                                                <div className="md:flex items-center">
                                                                    <div className="flex flex-col">
                                                                        <label className="mb-3 text-base leading-none text-gray-600">Action</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>}

                                                {(timeslots.filter(timeslot => timeslot.type === weekday)).map(timeslot => {
                                                    return <tr className={`${timeslot.isNew ? 'bg-yellow-300' : ''} border-b-2 border-white m-2 text-sm leading-none text-gray-600 h-16`}>
                                                        <td className="w-2/5">
                                                            <div className="flex items-center">
                                                                <div className="p-2">
                                                                    <div className="md:flex items-center">
                                                                        <div className="flex flex-col">
                                                                            {weekday === 'SPECIFIC' && <Datepicker date={new Date(timeslot.date)} onChange={v => setSingleItem(v, 'date', timeslot)}></Datepicker>}
                                                                            {weekday !== 'SPECIFIC' && <span>-</span>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td >
                                                            <div className="flex items-center">
                                                                <div className="p-2">
                                                                    <div className="md:flex items-center">
                                                                        <div className="flex flex-col">
                                                                            <TimeField
                                                                                input={<input className={`text-center border-gray-200 w-28 h-11 bg-gray-100 text-2xl font-base leading-none text-gray-800 p-2 border rounded`} />}
                                                                                onChange={(e, v) => setSingleItem(v, 'startAt', timeslot)}
                                                                                value={timeslot.startAt}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td >
                                                            <div className="flex items-center">
                                                                <div className="p-2">
                                                                    <div className="md:flex items-center">
                                                                        <div className="flex flex-col">
                                                                            <TimeField
                                                                                input={<input className={`text-center border-gray-200 w-28 h-11 bg-gray-100 text-2xl font-base leading-none text-gray-800 p-2 border rounded`} />}
                                                                                onChange={(e, v) => setSingleItem(v, 'endAt', timeslot)}
                                                                                value={timeslot.endAt}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td >
                                                            <div className="flex items-center">
                                                                <div className="p-2">
                                                                    <div className="md:flex items-center">
                                                                        <div className="flex flex-col">
                                                                            <input value={timeslot.slots} onChange={e => setSingleItem(e.target.value, 'slots', timeslot)} className={`text-center border-gray-200 w-28 h-11 bg-gray-100 text-sm font-medium text-2xl leading-none text-gray-800 p-2 border rounded`} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td >
                                                            <div className="flex items-center">
                                                                <div className="p-2">
                                                                    <div className="md:flex items-center">
                                                                        <div className="flex flex-col">
                                                                            <input value={timeslot.dysonSlots} onChange={e => setSingleItem(e.target.value, 'dysonSlots', timeslot)} className={`text-center border-gray-200 w-28 h-11 bg-gray-100 text-sm font-medium text-2xl leading-none text-gray-800 p-2 border rounded`} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td >
                                                            <div className="flex items-center">
                                                                <div className="p-2">
                                                                    <div className="md:flex items-center">
                                                                        <div className="flex flex-col">
                                                                            <button onClick={() => deleteTimeslot(timeslot)} className="h-10 bg-red-700 transition duration-150 ease-in-out hover:bg-red-600 rounded text-white px-5 text-base leading-6">Delete</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })}


                                            </tbody>
                                        </table>
                                    </div>
                                </div></Fragment>)
                        })}

                    </div>
                    <div className="mt-10 w-full py-4 sm:px-12 px-4 dark:bg-gray-700 flex justify-end rounded-bl rounded-br">
                        <button onClick={() => upsertTimeslots()} className="h-10 bg-indigo-700 focus:outline-none transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-8 py-2 text-base">Save Changes</button>
                    </div>
                </div>
                <style>
                    {` .checkbox:checked {
                        /* Apply class right-0*/
                        right: 0;
                    }
                    .checkbox:checked + .toggle-label {
                        /* Apply class bg-indigo-700 */
                        background-color: #4c51bf;
                    }`}
                </style>
            </div>
        </Fragment>)
}