import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    format,
    setMinutes,
    getMinutes,
    setHours,
    parse
} from "date-fns";
import axios from 'axios';
import {
    useQuery,
    useMutation,
} from 'react-query'
export default function Modal({ open, search, previousQ, setOpen, booking, refetch, showSuccessDialog }) {

    const updateMutation = useMutation(data => {
        return axios.put(`${process.env.REACT_APP_STRAPI_DOMAIN}/bookings/${booking.id}`, data)
    })

    const updateBooking = async (status) => {
        try {
            let updateObj = {
                status
            };
            const result = await updateMutation.mutateAsync(updateObj)
            if (result) {
                if (previousQ) {
                    search(previousQ);
                } else {
                    refetch();
                }

                setOpen(false);
                showSuccessDialog('Booking Status Successfully Changed');
            }
        } catch (error) {
            console.error(error)
        }
    }

    if (!booking) return null

    const startAt = parse(booking.timeslot.startAt, 'HH:mm:ss.SSS', new Date());
    const startTime = setMinutes(setHours(new Date(booking.bookingAt), startAt.getHours()), getMinutes(startAt));
    const doBook = async (action) => {
        updateBooking(action)
        showSuccessDialog('Booking Successfully Changed');
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-end justify-center h-5/6 pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        {booking && <div className="py-5 px-5 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <h1 className=" dark:text-gray-100 text-gray-800 font-bold text-lg">{booking.name} (# {booking.bookingId})</h1>
                            <p className="pt-6 text-sm font-semibold text-indigo-700 uppercase ">
                                Service Type:   <span className="font-extrabold">{booking.serviceType}</span>
                            </p>


                            <p className="pb-1 text-sm pt-4 font-normal  dark:text-gray-100 text-gray-600"><span className="font-semibold">Booking Date:</span> {format(new Date(startTime), 'd MMMM yyyy hh:mm a')}</p>
                            <p className="mb-5 text-sm text-gray-600 font-normal"><span className="font-semibold">Purchase Date:</span> {booking.purchasedAt ? format(new Date(booking.purchasedAt), 'd MMMM yyyy') : 'N/A'}</p>

                            <hr className="py-2" />

                            <div className="flex items-center">
                                <p className="mb-2 text-sm text-gray-600 font-normal"><span className="font-semibold">Symptom:</span> {booking.symptom || 'N/A'}</p>
                            </div>
                            <div className="flex items-center">
                                <p className="mb-2 text-sm text-gray-600 font-normal"><span className="font-semibold">ServiceNumber:</span> {booking.serviceNumber || 'N/A'}</p>
                            </div>
                            <div className="flex items-center">
                                <p className="mb-2 text-sm text-gray-600 font-normal"><span className="font-semibold">ProductName:</span> {booking.productName || 'N/A'}</p>
                            </div>
                            <div className="flex items-center">
                                <p className="mb-5 text-sm text-gray-600 font-normal"><span className="font-semibold">Subscribed:</span> {booking.isSubscribed ? 'YES' : 'NO'}</p>
                            </div>
                            <div className="flex items-center">
                                <p className="mb-5 text-sm text-gray-600 font-normal"><span className="font-semibold">Booked Via Additional Quota:</span> {booking.isBookedViaAddQuota ? 'YES' : 'NO'}</p>
                            </div>
                            <div className="flex items-center justify-between  ">
                                <button onClick={() => setOpen(false)} className=" py-3.5 w-full dark:text-gray-100 text-gray-600 focus:outline-none hover:opacity-90 text-sm font-semibold border border-gray-600 rounded">Dismiss</button>
                                {booking.status === 'OPEN' && <div className="pl-2 w-full">
                                    <button onClick={() => doBook('CANCELED')} className=" py-3.5 w-full dark:text-gray-100 text-white focus:outline-none hover:opacity-90 text-sm font-semibold border border-red-700 bg-red-700 rounded">Cancel</button>
                                </div>}
                                {booking.status === 'CANCELED' && <div className="pl-2 w-full">
                                    <button onClick={() => doBook('OPEN')} className=" py-3.5 w-full dark:text-gray-100 text-white focus:outline-none hover:opacity-90 text-sm font-semibold border border-green-600 bg-green-600 rounded">OPEN</button>
                                </div>}
                                {/* <div className="pl-2 w-full">
                                    <button onClick={() => setOpen(false)} className=" py-3.5 w-full text-white focus:outline-none hover:opacity-90 rounded border text-sm font-semibold border-indigo-700 bg-indigo-700">Done</button>
                                </div> */}
                            </div>

                            <style>
                                {` .checkbox:checked {
                border: none;
            }
            .checkbox:checked + .check-icon {
                display: flex;
            }`}
                            </style>

                        </div>}
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}