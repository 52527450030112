import Cookies from 'universal-cookie';
import jwt_decode from "jwt-decode";

const cookies = new Cookies();

const verifyToken = (setToken = null, setUser = null) => {
    let isLogin = false;
    let tokenData = null;

    const token = cookies.get('_token');
    const user = cookies.get('_user');
    if (token) {
        const decoded = jwt_decode(token);
        if (decoded) {
            let current_time = new Date().getTime() / 1000;
	        if (current_time < decoded.exp) {
                if (setToken && setUser) {
                    setToken(token)
                    setUser(user)
                }
                return {
                    isLogin: true,
                    tokenData: token
                }
             }
        }
    }

    return {
        isLogin,
        tokenData
    }
}

export default verifyToken