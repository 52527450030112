import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    useQuery,
    useMutation,
} from 'react-query'
import axios from 'axios';
import randomstring from 'randomstring';

export default function AccountModal({
    open,
    setOpen,
    account,
    isSubmitted,
    setIsSubmitted,
    showSuccessDialog,
    refetch }) {

    const [jebsenRole, setJebsenRole] = useState('ADMIN')
    const [hasError, setHasError] = useState(false);
    const [email, setEmail] = useState('');
    const [blocked, setBlocked] = useState('ACTIVE');
    const [name, setName] = useState('');
    const [accountId, setAccountId] = useState('');
    const [pwd, setPwd] = useState('');
    const [rePwd, setRePwd] = useState('');

    useEffect(() => {
        if (!hasError && isSubmitted) {
            getAccountAction(account)
        }
    }, [hasError, isSubmitted, setOpen])

    useEffect(() => {
        if (account) {
            setEmail(account.email)
            setBlocked(account.blocked)
            setJebsenRole(account.jebsenRole)
            setName(account.username)
            setAccountId(account.id)
            setPwd('')
            setRePwd('')
        } else {
            setEmail('')
            setName('')
            setBlocked(false)
            setJebsenRole('ADMIN')
            setPwd('')
            setRePwd('')
        }
    }, [account])

    const updateMutation = useMutation(data => {
        return axios.put(`${process.env.REACT_APP_STRAPI_DOMAIN}/users/${account.id}`, data)
    })

    const createMutation = useMutation(data => {
        return axios.post(`${process.env.REACT_APP_STRAPI_DOMAIN}/users`, data)
    })

    const updateAccount = async () => {
        try {
            let updateObj = {
                jebsenRole,
                username: name,
                blocked,
                email
            };
            if (pwd) {
                updateObj = {
                    ...updateObj,
                    password: pwd
                }
            }
            const result = await updateMutation.mutateAsync(updateObj)
            if (result) {
                refetch();
                setOpen(false);
                showSuccessDialog('Account Data Successfully Saved');
            }
        } catch (error) {
            console.error(error)
        }
    }

    const createAccount = async () => {
        try {
            const result = await createMutation.mutateAsync({
                jebsenRole,
                username: name,
                blocked,
                password: pwd,
                email
            })
            if (result) {
                refetch();
                setOpen(false);
                showSuccessDialog('Account Successfully Created');
            }
        } catch (error) {
            console.error(error)
        }
    }

    const DEFAULT_ERROR_MSG = 'Invalid field 資料有誤，請檢查此欄'
    const PW_ERROR_MSG = 'Invalid password entered 輸入不正確，請確保與確認密碼一致'

    const validEmail = new RegExp(
        '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
    );

    const isError = (field, value, isMandatory = false) => {
        if (field === 'email' && isSubmitted) {
            if (!validEmail.test(value) && !hasError) {
                setHasError(true);
            }
            return !validEmail.test(value);
        }

        if (field === 'pwd' && isSubmitted) {
            if (pwd !== rePwd && !hasError) {
                setHasError(true)
            }
            return pwd !== rePwd
        }

        if (isMandatory && isSubmitted) {
            if (!value && !hasError) {
                setHasError(true);
            }
            return !value;
        }
    }

    const getAccountTitle = (account) => {
        if (account) {
            return `${account.username} (# ${account.id})`;
        }
        return 'Create new account'
    }

    const getAccountAction = (account) => {
        if (account) {
            return updateAccount();
        }
        return createAccount();
    }

    const attemptSubmission = () => {
        setHasError(false);
        setIsSubmitted(true);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setOpen}>
                <div className="flex items-end justify-center h-5/6 pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="py-5 px-5 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <h1 className="text-gray-800 font-bold text-lg">{getAccountTitle(account)}</h1>
                            <div className="flex flex-col mt-6 w-full">
                                <label className="mb-3 text-base leading-none text-gray-600">Role</label>
                                <div className="relative ">
                                    <div className="w-full w-auto mt-4 sm:mt-0 py-3 px-4 flex items-center text-sm font-medium leading-none text-gray-600 bg-gray-100 hover:bg-gray-200 cursor-pointer rounded">
                                        <select value={jebsenRole} onChange={(event) => setJebsenRole(event.target.value)} className="w-full focus:outline-none bg-transparent ml-1">
                                            <option value="ADMIN">Administrator</option>
                                            {/* <option value="DYSON_USER">Dyson User</option> */}
                                            <option value="PCCW_USER">PCCW User</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col mt-6">
                                <label className="mb-3 text-base leading-none text-gray-600">Name</label>
                                <input value={name} onInput={e => setName(e.target.value)} type="text" placeholder="Enter name" tabIndex={0} aria-label="Enter name" className={`${isError('name', name, true) ? 'border-red-500' : 'border-gray-200'} w-full bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded`} />
                                {isError('name', name, true) && <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {DEFAULT_ERROR_MSG}
                                </span>}
                            </div>

                            <div className="flex flex-col mt-6">
                                <label className="mb-3 text-base leading-none text-gray-600">Email</label>
                                <input value={email} onInput={e => setEmail(e.target.value)} type="email" placeholder="Enter email" tabIndex={0} aria-label="Enter email" className={`${isError('email', email, true) ? 'border-red-500' : 'border-gray-200'} w-full bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded`} />
                                {isError('email', email, true) && <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {DEFAULT_ERROR_MSG}
                                </span>}
                            </div>

                            <div className="flex flex-col mt-6">
                                <label className="mb-3 text-base leading-none text-gray-600">Password</label>
                                <input value={pwd} onInput={e => setPwd(e.target.value)} type="password" placeholder="Enter password" tabIndex={0} aria-label="Enter password" className={`${isError('pwd', pwd, false) ? 'border-red-500' : 'border-gray-200'} w-full bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded`} />
                                {isError('pwd', pwd, false) && <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {PW_ERROR_MSG}
                                </span>}
                            </div>

                            <div className="flex flex-col mt-6">
                                <label className="mb-3 text-base leading-none text-gray-600">Re-confirm Password</label>
                                <input value={rePwd} onInput={e => setRePwd(e.target.value)} type="password" placeholder="Enter re-confirm password" tabIndex={0} aria-label="Enter re-confirm password" className={`${isError('rePwd', rePwd, false) ? 'border-red-500' : 'border-gray-200'} w-full bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded`} />
                                {isError('rePwd', rePwd, false) && <span class="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                    {PW_ERROR_MSG}
                                </span>}
                            </div>

                            <div className="flex flex-col mt-6">
                                <label className="mb-3 text-base leading-none text-gray-600">Status</label>
                                <div className="relative">
                                    <div className="w-full w-auto mt-4 sm:mt-0 py-3 px-4 flex items-center text-sm font-medium leading-none text-gray-600 bg-gray-100 hover:bg-gray-200 cursor-pointer rounded">
                                        <select value={blocked} onChange={(event) => setBlocked(event.target.value)} className="w-full focus:outline-none bg-transparent ml-1">
                                            <option value={false}>Active</option>
                                            <option value={true}>Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between mt-10">
                                <button onClick={() => setOpen(false)} className=" py-3.5 w-full text-gray-600 focus:outline-none hover:opacity-90 text-sm font-semibold border border-gray-600 rounded">Dismiss</button>
                                <div className="pl-2 w-full">
                                    <button onClick={() => attemptSubmission()} className=" py-3.5 w-full text-white focus:outline-none hover:opacity-90 text-sm font-semibold border border-green-700 bg-green-600 rounded">{account ? 'Save' : 'Create'}</button>
                                </div>
                            </div>

                            <style>
                                {` .checkbox:checked {
                border: none;
            }
            .checkbox:checked + .check-icon {
                display: flex;
            }`}
                            </style>

                        </div>

                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}