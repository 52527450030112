import React from "react";

function Index() {
    return (
        <>
            {/* To use this card, please use [6x6] grid from Grids. */}
            {/* Card code block start */}

            <div className="border h-screen w-full mx-auto bg-white border-gray-400 rounded">
                <div className="flex justify-center border-b border-gray-200 mt-10">
                    <div className="flex pb-6">
                        <div className="h-60 w-60">
                            <img src="https://jebsenconsumerproductsservicecentrehk.simplybook.asia/uploads/jebsenconsumerproductsservicecentrehk/image_files/preview/dc214d40a34bca1b12f34b793bd628fa.jpg" alt className="h-full w-full object-cover overflow-hidden" />
                        </div>
                    </div>
                </div>
                <div className="mt-10">
                    <div className="flex justify-center pb-10">
                        <div className="pl-4">
                            <p className="text-center text-4xl text-gray-800">取籌處<br />Ticker Dispenser</p>
                        </div>
                    </div>
                    <div>
                        <div className="mt-4 flex w-full justify-center items-center">
                            <div className="flex w-full justify-center items-center">
                                <button className="shadow-lg w-80 h-40 bg-white transition duration-150 ease-in-out rounded border-2 border-jebsen-default text-jebsen-default px-16 py-4 text-3xl">已網上預約<br />Online Reserved</button>
                            </div>
                        </div>
                        <div className="mt-12 flex w-full justify-center items-center">
                            <div className="flex w-full justify-center items-center">
                                <button className="shadow-lg w-80 h-40 bg-white transition duration-150 ease-in-out rounded border-2 border-jebsen-default text-jebsen-default px-16 py-4 text-3xl">無預約<br />Walk-in</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Card code block end */}
        </>
    );
}
export default Index;
