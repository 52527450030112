import { Fragment, useState, useRef, useEffect } from 'react'
import {
    useQuery,
    useMutation,
} from 'react-query'
import Modal from '../BrandModal';
import axios from 'axios';
import { orderBy } from 'lodash'
export default function Config({ setDrawerShow, setProduct, showSuccessDialog }) {
    const DEFAULT_DELAY = 1000;

    const [currentBrand, setCurrentBrand] = useState(null);
    const [brands, setBrands] = useState([]);
    const [openCreationModal, setOpenCreationModal] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [action, setAction] = useState('UPDATE');

    useEffect(() => {
        setDrawerShow(false);
        setProduct(false);
    }, []);

    const deleteMutation = useMutation(brand => {
        return axios.delete(`${process.env.REACT_APP_STRAPI_DOMAIN}/brands/${brand.id}`);
    })

    const deleteBrand = async (brand) => {
        try {
            const result = await deleteMutation.mutateAsync(brand)
            if (result) {
                setTimeout(() => {
                    refetch();
                    showSuccessDialog('Brand Successfully Deleted');
                }, DEFAULT_DELAY);
            }
        } catch (error) {
            console.error(error)
        }
    }

    const { refetch } = useQuery('brands', () =>
        fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/brands`)
            .then((res) =>
                res.json()
            ).then(data => {
                setBrands(orderBy(data, ['sorting'], ['asc']));
                return data;
            }),
        { refetchOnWindowFocus: false })

    const openModal = (brand, act) => {
        setCurrentBrand(brand);
        setAction(act)
        setOpenCreationModal(true);
        setIsSubmitted(false)
    }

    return (
        <Fragment>
            <Modal brand={currentBrand} open={openCreationModal} isSubmitted={isSubmitted} setIsSubmitted={setIsSubmitted} setOpen={setOpenCreationModal} showSuccessDialog={showSuccessDialog} refetch={refetch} />
            <div className="animate-fade-in-down">
                <div className="xl:w-full border-b border-gray-300 py-5">
                    <div className="flex items-center w-11/12 mx-auto">
                        <div className="container mx-auto">
                            <div className="">
                                <p className="text-2xl text-gray-800 font-bold">Brands</p>
                                <p className="text-sm text-gray-500 pt-1">Configurations for Jebsen Online Booking System
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="container mx-auto bg-white dark:bg-gray-800 py-6 w-11/12">
                        <table className="w-full whitespace-nowrap">
                            <thead>
                                <tr className="h-20 w-full text-sm leading-none text-gray-600">
                                    <th className="font-normal text-left pl-10">Brand #</th>
                                    <th className="font-normal text-left pl-10">Name</th>
                                    <th className="font-normal text-left pl-10">Display Ordering</th>
                                    <th className="font-normal text-left pl-10 w-32">Action</th>
                                </tr>
                            </thead>
                            {brands && <tbody className="w-full">
                                {brands.map((brand, index) => {

                                    return (<tr key={index} className="cursor-pointer h-14 text-sm leading-none text-gray-700 border-b border-t border-gray-200 ">
                                        <td onClick={() => openModal(brand, 'UPDATE')} className="pl-10 uppercase text-base text-purple-600"><div className="mb-0 bg-gray-200 dark:bg-gray-800 h-8 w-12 rounded-full flex items-center justify-center">
                                            <span className="font-semibold text-xs text-gray-600 dark:text-gray-400 font-normal">{brand.id}</span>
                                        </div></td>
                                        <td onClick={() => openModal(brand, 'UPDATE')} className="pl-10">
                                            <div className="flex items-center">
                                                {brand.name}
                                            </div>
                                        </td>
                                        <td onClick={() => openModal(brand, 'UPDATE')} className="pl-10">
                                            <div className="flex items-center">
                                                {brand.sorting}
                                            </div>
                                        </td>
                                        <td className="pl-10"><button onClick={() => deleteBrand(brand)}className="focus:outline-none bg-red-600 mr-5 hover:bg-gray-200 py-2.5 px-5 rounded text-sm leading-3 text-white">Delete</button></td>
                                    </tr>)
                                })}
                            </tbody>}
                        </table>
                        <div className="mt-20 w-full flex justify-end rounded-bl rounded-br">
                            <button onClick={() => openModal(null, 'CREATE')} className="h-10 bg-indigo-700 focus:outline-none transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-8 py-2 text-sm">Add Brand</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>)
}