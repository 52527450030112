import { Fragment, useState, useEffect } from 'react'
import Modal from '../AccountModal';
import {
    useQuery,
    useMutation,
} from 'react-query'
import qs from 'qs';
import { capitalize, startCase, has } from 'lodash';
export default function AccountList({ setDrawerShow, setProduct, showSuccessDialog }) {
    useEffect(() => {
        setDrawerShow(false);
        setProduct(false);
    }, []);

    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [serachKeyword, setSearchKeyword] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [statusFilter, setStatusFilter] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [action, setAction] = useState('UPDATE');

    // Queries
    const accountQuery = useQuery('accounts', () =>
        fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/users`)
            .then((res) =>
                res.json()
            ).then(data => {
                setAccounts(data);
                return data;
            }),
        { refetchOnWindowFocus: false })

    const getStatusColor = (status) => {
        let color;
        switch (status) {
            case false: color = 'text-green-500'; break;
            default: color = 'text-red-500'; break; // CANCELED
        }
        return color;
    }

    const openModal = (account, act) => {
        setCurrentAccount(account);
        setAction(act)
        setOpenDetailModal(true);
        setIsSubmitted(false)
    }

    const searchBoxOnChange = (event) => {
        const value = event.target.value;
        setSearchKeyword(value);
        search({ keyword: value });
    }

    const search = (q) => {
        const _searchKeyword = has(q, 'keyword') ? q.keyword : serachKeyword;
        const _statusFilter = has(q, 'status') ? q.status : statusFilter;

        let nameCondition = [];
        let idCondition = [];
        let emailCondition = [];

        if (_searchKeyword) {
            nameCondition.push({ username_contains: _searchKeyword });
            idCondition.push({ id_contains: _searchKeyword });
            emailCondition.push({ email_contains: _searchKeyword });
        }

        if (_statusFilter) {
            nameCondition.push({ blocked: _statusFilter });
            idCondition.push({ blocked: _statusFilter });
            emailCondition.push({ blocked: _statusFilter });
        }

        const query = qs.stringify({ _where: { _or: [nameCondition, idCondition, emailCondition] } });
        fetch(`${process.env.REACT_APP_STRAPI_DOMAIN}/users?${query}`)
            .then((res) => res.json())
            .then(data => {
                setAccounts(data);
            })
    }

    const statusFilterOnChange = (event) => {
        const value = event.target.value;

        if (value) {
            setStatusFilter(value);
            search({ blocked: value });
        } else {
            setStatusFilter(null);
            search({ blocked: null });
        }
    }

    return (
        <Fragment>
            <Modal action={action} open={openDetailModal} isSubmitted={isSubmitted} setIsSubmitted={setIsSubmitted} setOpen={setOpenDetailModal} account={currentAccount} showSuccessDialog={showSuccessDialog} refetch={accountQuery.refetch} />
            <div className="animate-fade-in-down">
                <div className="mb-5 sm:mb-10 rounded-tl-lg rounded-tr-lg">
                    <div className="sm:flex items-center justify-between">
                        {accounts && <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">{accounts.length} records</p>}
                        <div className="flex items-center mt-4 sm:mt-0">
                            <div className="flex items-center pl-3 bg-white border rounded-md border-gray-200">
                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none">
                                    <path d="M8.33333 13.1667C11.555 13.1667 14.1667 10.555 14.1667 7.33333C14.1667 4.11167 11.555 1.5 8.33333 1.5C5.11167 1.5 2.5 4.11167 2.5 7.33333C2.5 10.555 5.11167 13.1667 8.33333 13.1667Z" stroke="#9CA3AF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.5 17.5L12.5 12.5" stroke="#9CA3AF" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <input onChange={e => searchBoxOnChange(e)} type="text" className="py-2.5 pl-2 w-40 sm:w-64 focus:outline-none text-sm rounded-md text-gray-600 placeholder-gray-400" placeholder="Name / Email / Account #" />
                            </div>
                            <div className="hidden md:flex items-center mt-4 md:mt-0 md:ml-3 lg:ml-0 mr-1">
                                <div className="w-40 py-2 px-3 bg-white lg:ml-3 border rounded-md border-gray-200">
                                    <select onChange={e => statusFilterOnChange(e)} className="w-full text-sm leading-3 text-gray-500 focus:outline-none">
                                        <option value="">Filter Status</option>
                                        <option value={false}>Active</option>
                                        <option value={true}>Inactive</option>
                                    </select>
                                </div>
                            </div>
                            <button onClick={() => openModal(null, 'CREATE')} className="inline-flex ml-3 whitespace-nowrap items-start justify-start px-6 py-3 bg-indigo-700 hover:bg-indigo-600 focus:outline-none rounded">
                                <p className="text-xs sm:text-sm font-medium leading-none text-white">New User</p>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-full overflow-x-auto  h-screen">
                    <table className="w-full whitespace-nowrap">
                        <thead>
                            <tr className="h-20 w-full text-sm leading-none text-gray-600">
                                <th className="font-normal text-left pl-4">Username</th>
                                <th className="font-normal text-left pl-10">Account #</th>
                                <th className="font-normal text-left pl-10">Role</th>
                                <th className="font-normal text-left pl-10">Email</th>
                                <th className="font-normal text-left pl-10">Status</th>
                            </tr>
                        </thead>
                        {accounts && <tbody className="w-full">
                            {accounts.map((account, index) => {
                                return (<tr key={index} onClick={() => openModal(account, 'UPDATE')} className="cursor-pointer h-20 text-sm leading-none text-gray-700 border-b border-t border-gray-200 bg-white hover:bg-gray-50">

                                    <td className="font-medium pl-4 text-sm">
                                        {account.username}
                                    </td>
                                    <td className="pl-10 uppercase text-sm text-purple-600"><div className="mb-0 bg-gray-200 dark:bg-gray-800 h-8 w-24 rounded-full flex items-center justify-center">
                                        <span className="font-semibold text-xs text-gray-600 dark:text-gray-400 font-normal">{account.id}</span>
                                    </div></td>
                                    <td className="pl-10 font-semibold text-sm">
                                        <div className="flex items-center">
                                            {capitalize(startCase(account.jebsenRole))}
                                        </div>
                                    </td>
                                    <td className="pl-10">{account.email}</td>
                                    <td className="pl-10">
                                        <div className="w-20 h-6 flex items-center justify-center bg-green-50 rounded-full">
                                            <p className={`font-semibold text-xs capitalize leading-3 ${getStatusColor(account.blocked)}`}>{account.blocked ? 'INACTIVE' : 'ACTIVE'}</p>
                                        </div>
                                    </td>
                                </tr>)
                            })}
                        </tbody>}
                    </table>
                </div>
            </div>
        </Fragment>)
}