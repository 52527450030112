
import React, { Component } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import qrImg from '../qr.jpeg';

function Index() {

    return (
        <>
            {/* To use this card, please use [6x6] grid from Grids. */}
            {/* Card code block start */}

            <div className="border h-screen w-full mx-auto bg-white border-gray-400 rounded">
                <div className="flex justify-center border-b border-gray-200 mt-10">
                    <div className="flex pb-6">
                        <div className="h-60 w-60">
                            <img src="https://jebsenconsumerproductsservicecentrehk.simplybook.asia/uploads/jebsenconsumerproductsservicecentrehk/image_files/preview/dc214d40a34bca1b12f34b793bd628fa.jpg" alt className="h-full w-full object-cover overflow-hidden" />
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <div className="flex justify-center pb-3">
                        <div className="pl-4">
                            <p className="text-center text-4xl text-gray-800">網上預約<br />Online Reserved</p>
                            <p className="mt-4 text-center text-2xl text-gray-800">在下方掃描預約電郵裡的二維碼<br />Scan the QR code in the email below</p>
                            <div className="flex justify-center ">
                                <img className="w-1/2 mt-4" src={qrImg} alt="Jebsen Consumer Products Service Centre" />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center pb-3">
                        <div className="pl-4">
                            <p className="mt-4 font-bold text-center text-3xl text-gray-800">或 Or</p>
                        </div>
                    </div>
                    <div className="flex justify-center  pb-3">
                        <div className="pl-4">
                            <p className="mt-4 text-center text-2xl text-gray-800">請輸入你的電話號碼<br />Please input your phone number</p>
                        </div>
                    </div>
                    <div>
                        <div className="mt-2 flex justify-center items-center">
                            <div className="flex w-3/4  justify-center items-center">
                                <input type="text" placeholder="電話號碼 Phone number" className={`h-16 w-full bg-white text-2xl font-medium leading-none text-gray-800 p-4 border-4 rounded border-jebsen-default`} />
                            </div>
                        </div>
                        <div className="mt-4 flex justify-center items-center">
                            <div className="flex w-3/4  justify-center items-center">
                                <Keyboard layout={{
                                    default: ["7 8 9", "4 5 6", "1 2 3", "0 {bksp} {enter}"],
                                }}
                                    display={{
                                        '{bksp}': '⌫',
                                        '{enter}': '取票 Ticket',
                                    }}
                                    theme={"hg-theme-default hg-layout-numeric numeric-theme"}
                                    buttonTheme={[
                                        {
                                            class: "bg-jebsen-default text-white text-3xl font-medium h-16",
                                            buttons: "{enter}"
                                        },
                                        {
                                            class: "text-3xl font-medium h-16",
                                            buttons: "1 2 3 4 5 6 7 8 9 0 {bksp}"
                                        },
                                    ]}
                                />

                            </div>
                        </div>
                        <div className="mt-10 flex w-full justify-center items-center">
                            <div className="flex w-full justify-center items-center">
                                <button className="shadow-lg w-40 h-24 bg-white transition duration-150 ease-in-out rounded border-2 border-gray-600 text-gray-600 text-3xl">返回<br />Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Card code block end */}
        </>
    );
}
export default Index;
